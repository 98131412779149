import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { Icon } from "@iconify/react";
import { ProjectProps, SourcesType } from "@/Types/projects";
import SelectedCard from "@/Components/SelectedCard";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import Card from "./Partials/Card";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import EditButton from "@/Components/Projects/EditButton";
import GenerateLoading from "@/Components/GenerateLoading";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SourcesList from "@/Components/Projects/SourcesList";
import { useGenerate } from "@/Context/hooks/useGenerate";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { SectionArchetypeType } from "@/Types/challenge_and_task";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { progressPortion } from "@/Utils/dispatcher";
import { useNavigate } from "react-router-dom";
import useUser from "@/Hooks/react-query/useUser";
import { ChallengeSchema } from "@/Types/challenge_and_task/schema";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import useChallengeAndTasks from "@/Hooks/react-query/challenge-and-task/useChallengeAndTask";
import { useUpdateChallengeAndTask } from "@/Hooks/react-query/challenge-and-task";
import { SectionList } from "@/Types/tabs";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import { useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<SectionArchetypeType[]>([]);
  const [editedData, setEditedData] = useState<SectionArchetypeType[]>([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeItem, setActiveItem] = useState<SectionArchetypeType>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const { data: user } = useUser();
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const maxGenerateLimit = project.max_generated_data;
  const { roles } = useRolesBadge(project, user);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [totalSelectedChallenge, setTotalSelectedChallenge] =
    useState<number>(0);
  const [totalChallenge, setTotalChallenge] = useState<number>(0);

  const { handleGenerateChallengeAndTask, handleGenerateCompetitorAnalysis } =
    useGenerate(dispatch);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const {
    isChallengeAndTaskOfTheBrand,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleCancelEdit = () => {
    toggleIsEditing();
  };

  const navigate = useNavigate();
  const { mutate } = useUpdateChallengeAndTask();

  const { data, isLoading, refetch, isRefetching } =
    useChallengeAndTasks(project);

  const handleRegenerateButton = async (data: { prompt: string }) => {
    setParsedData([]);
    setErrorTabIndex([]);
    setEditedData([]);
    setIsGenerated(true);
    dispatch(saveSubInformation(""));
    await handleGenerateChallengeAndTask()(project.slug, data.prompt);
  };

  const routeOptionValue: RouteOption = {
    label: "Generate Competitor Analysis",
    isActive: true,
    isGenerate: true,
    isInactive: true,
  };

  const hasSelectedChallenge = (selectedData: SectionArchetypeType[]) => {
    const dataToUse = selectedData?.length ? selectedData : parsedData;

    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = dataToUse
      .map((section, index) => {
        const hasSelectedKeyInsight = section.challenges.some(
          (challenge) => challenge.is_selected === 1,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(", ");

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select challenge & communication task for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }

    dispatch(saveSubInformation(``));
    return false;
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleFetchSubmit = async (
    payload: ChallengeSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      {
        payload,
        id,
        archetypeId: activeItem?.id!,
        projectSlug,
      },
      {
        onSuccess: () => {
          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);

          setActiveItem(activeItem);

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const onSelectSuccess = (data: any) => {
    setShowAlertGenerateModal(data.is_show_pop_up);
    refetch();
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ["OnePageStrategy", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/challenge-and-task/check-regenerate`,
    );

    if (
      (data && data.data.is_show_pop_up) ||
      isCompetitorAnalysisComplete.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isCompetitorAnalysisComplete.isRegenerated,
      );
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/competitor-analysis`);
  };

  const handleRegenerate = async () => {
    if (
      differentArchetypes?.is_different_archetype.challenges ||
      isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      setIsReGenerating(true);
      setIsGenerated(true);
      await handleGenerateChallengeAndTask()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
      return;
    }

    if (isCompetitorAnalysisComplete.isRegenerated) {
      setIsReGenerating(true);
      setIsGenerated(true);
      await handleGenerateCompetitorAnalysis()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
    }

    navigate(`/${project.slug}/competitor-analysis`);
  };

  useEffect(() => {
    const totalSelected = data?.data?.data?.total_selected_challenges || 0;
    const totalData = data?.data?.data?.total_challenges || 0;

    setTotalChallenge(totalData);
    setTotalSelectedChallenge(totalSelected);

    if (data && data.data && data.data.data) {
      const {
        data: challenges,
        sources,
        total_regenerate,
        is_querying,
      } = data.data.data;

      if (is_querying) {
        setIsGenerated(true);
        setParsedData([]);
        setEditedData([]);
        setTotalRegenerate(0);
        setSources([]);

        return;
      }

      if (challenges.length > 0) {
        setParsedData(challenges);
        setEditedData(challenges);
        setTotalRegenerate(total_regenerate);
        setSources(sources);
        setIsGenerated(false);
      }

      dispatch(saveSubInformation(""));
    }
  }, [
    data,
    isChallengeAndTaskOfTheBrand,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  useEffect(() => {
    !isChallengeAndTaskOfTheBrand.isQuerying && !isPendingHistory && refetch();
    isChallengeAndTaskOfTheBrand.isQuerying &&
      !isPendingHistory &&
      setIsGenerated(true);
  }, [
    isChallengeAndTaskOfTheBrand,
    isGenerated,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  useEffect(() => {
    if (!isChallengeAndTaskOfTheBrand.status) {
      dispatch(
        saveInformation(`0 from 0 challenge & communication task selected`),
      );
    }

    if (isChallengeAndTaskOfTheBrand.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    !isPendingHistory &&
      isKeyInsightsComplete.isQuerying &&
      setIsGenerated(true);
  }, [isChallengeAndTaskOfTheBrand, isPendingHistory]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.challenges
    ) {
      if (
        !isGenerated &&
        !isPendingHistory &&
        !isChallengeAndTaskOfTheBrand.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveSubInformation(""));
        handleGenerateChallengeAndTask()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isChallengeAndTaskOfTheBrand,
    isPendingHistory,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/key-insight`),
      }),
    );
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    if (!parsedData.length) return;
    setSelectedIndex([]);

    const activeItem = parsedData[activeTab];
    const selectedDataWithIndex = activeItem?.challenges
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);

      if (errorTabIndex && errorTabIndex.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== activeTab) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(", ");

        dispatch(saveSubInformation(""));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select challenge & communication task for ${namesWithoutSelectedKeyInsight}`,
            ),
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }

    setActiveItem(activeItem);
  }, [activeTab, parsedData]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [activeTab]);

  const countSelectedData = (data: SectionArchetypeType[]) => {
    const archetypeCounts = data.map((archetype) => {
      let totalArchetype = 0;
      let totalSelected = 0;

      if (archetype.challenges && Array.isArray(archetype.challenges)) {
        archetype.challenges.forEach((challenge) => {
          totalArchetype++;
          if (challenge.is_selected === 1) {
            totalSelected++;
          }
        });
      }

      return {
        archetypeId: archetype.id,
        totalArchetype,
        totalSelected,
      };
    });

    const isInactive = archetypeCounts.some(
      (archetype) => archetype.totalSelected === 0,
    );

    return isInactive;
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totalSelectedChallenge} from ${totalChallenge} challenge & communication task selected`,
      ),
    );
  }, [totalSelectedChallenge, totalChallenge]);

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        "scrollIntoView" in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        "scrollIntoView" in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: "Challenge & Communication Task",
    value: "challenges",
    section: "brand",
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    if (isEditor) {
      const isInactive =
        isGenerated ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        !isChallengeAndTaskOfTheBrand.status
          ? true
          : countSelectedData(parsedData);
      routeOptionValue.isInactive = isInactive;

      if (
        isCompetitorAnalysisComplete.status &&
        isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = "Regenerate Competitor Analysis";
        routeOptionValue.isGenerate = true;
      }

      if (
        !isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = "Generate Competitor Analysis";
        routeOptionValue.isGenerate = true;
      }

      if (
        isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = "Next to Competitor Analysis";
        routeOptionValue.isGenerate = false;
      }
    } else {
      routeOptionValue.label = "Next to Competitor Analysis";
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isCompetitorAnalysisComplete.status ||
        isCompetitorAnalysisComplete.isQuerying;
    }

    routeOptionValue.onClick = () => {
      if (
        !isChallengeAndTaskOfTheBrand.status ||
        isGenerated ||
        isChallengeAndTaskOfTheBrand.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait challenge & communication Task is still generating`,
          ),
        );
        return;
      }

      if (!isEditor && !isCompetitorAnalysisComplete.status) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor) {
        if (!isKeyInsightsComplete.status) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait key insight is still generating`,
            ),
          );
          return;
        }

        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, competitor task is still generating`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(""));
          }, 3000);
          return;
        }

        if (isEditor && (isAdding || isEditing)) {
          dispatch(
            saveSubInformation(`{text-error-redx} Please do save first`),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(""));
          }, 3000);
          return;
        }

        const hasError = hasSelectedChallenge(parsedData);
        if (!hasError)
          routeOptionValue.isGenerate
            ? handleRefetchSelectedData()
            : navigate(`/${project.slug}/competitor-analysis`);
        return;
      }

      navigate(`/${project.slug}/competitor-analysis`);
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
    parsedData,
    isGenerated,
    isCompetitorAnalysisComplete,
    showRegenerateModal,
    isEditor,
    isAdding,
    isEditing,
  ]);

  return (
    <Fragment>
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.challengeAndTask.progress / 2).toFixed(0)),
            isQuerying: isChallengeAndTaskOfTheBrand.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isChallengeAndTaskOfTheBrand.isSendingEmail}
        />
      ) : (
        <>
          <div className='flex md:flex-col flex-row items-center justify-between gap-16 sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col grow'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Challenge & Communication Task of the Brand
              </h1>
              <span className='text-15 font-normal text-grey-redx'>
                Please select one or multiple challenge & task or add another by
                clicking ‘Add Challenge & Communication Task’ button.
              </span>
            </div>
            <div className='flex items-center'>
              {isEditor && (
                <RegenerateButton
                  isGenerateMore
                  limit={totalRegenerate}
                  maxLimit={maxGenerateLimit}
                  onSubmit={handleRegenerateButton}
                />
              )}
            </div>
          </div>

          <TabGroup
            className='pb-80'
            selectedIndex={activeTab}
            onChange={setActiveTab}>
            <div className='relative flex items-center'>
              {!isLoading &&
                !isChallengeAndTaskOfTheBrand.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handlePrev}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={activeTab === 0}>
                    <Icon
                      icon='mingcute:left-line'
                      className='text-25'
                    />
                  </button>
                )}
              <TabList
                className={
                  "flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200"
                }>
                {!isLoading && !isChallengeAndTaskOfTheBrand.isQuerying
                  ? parsedData.map((item, dataIndex) => (
                      <Tab
                        disabled={isEditing}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[dataIndex] = el;
                          }
                        }}
                        className='text-15 px-25 pb-10 border-b-1 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                        key={dataIndex}>
                        <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                          <div className='flex flex-col gap-8'>
                            {`Archetype ${dataIndex + 1}`}
                            <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                              {item.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex &&
                            errorTabIndex.includes(dataIndex) && (
                              <Icon
                                icon='mingcute:information-line'
                                className='text-25 text-error-redx'
                              />
                            )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isChallengeAndTaskOfTheBrand.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handleNext}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={activeTab === parsedData.length - 1}>
                    <Icon
                      icon='mingcute:right-line'
                      className='text-25'
                    />
                  </button>
                )}
            </div>
            {!isLoading && !isChallengeAndTaskOfTheBrand.isQuerying ? (
              <TabPanels className={"py-24"}>
                {parsedData.map((item, index) => (
                  <TabPanel key={index}>
                    <div
                      className='pt-5'
                      key={index}>
                      <SelectedCard
                        title={`Archetype Detail - ${item.archetype_content.archetype_name}`}
                        data={[
                          {
                            title: "Archetype Name",
                            content: item.archetype_content.archetype_name,
                          },
                          {
                            title: "Demographic",
                            content: item.archetype_content.demographics,
                          },
                          {
                            title: "Occupation",
                            content: item.archetype_content.occupation,
                          },
                          {
                            title: "Lifestyle",
                            content: item.archetype_content.lifestyle,
                          },
                          {
                            title: "Behavior",
                            content: item.archetype_content.behavior,
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              item.archetype_content.needs_and_challenges,
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              item.archetype_content.potential_buying_motives,
                          },
                          {
                            title: "Current Trends",
                            content: item.archetype_content.current_trends,
                          },
                          {
                            title: "Source of Information",
                            content:
                              item.archetype_content.source_of_information,
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              item.archetype_content.online_search_behavior,
                          },
                          {
                            title: "Purchase Frequency",
                            content: item.archetype_content.purchase_frequency,
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              item.archetype_content.preferred_sales_channels,
                          },
                        ]}
                      />
                      <div className='mt-20' />
                      <SliderContainer
                        isEditing={isEditing}
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        length={item.challenges.length}
                        onActiveIndex={(index) => setActiveIndex(index)}
                        selectedIndexes={selectedIndex}>
                        {item.challenges.length &&
                          item.challenges.map((challenge, index) => (
                            <Card
                              id={challenge.id}
                              project={project}
                              isAdding={isAdding}
                              onSelectSuccess={onSelectSuccess}
                              totalData={item.challenges.length}
                              key={index}
                              isFetching={isRefetching}
                              item={challenge.challenges_content}
                              index={index}
                              isEditing={isEditing}
                              isSelected={!!challenge.is_selected}
                              activeIndex={activeIndex}
                              ref={(el) => (cardRefs.current[index] = el)}
                              onSubmitSuccess={handleFetchSubmit}
                              isCanSelect={isEditor}
                            />
                          ))}
                        {isAdding && (
                          <Card
                            id={-1}
                            onSelectSuccess={onSelectSuccess}
                            project={project}
                            totalData={item.challenges.length + 1}
                            item={{
                              challenge: "",
                              communication_task: "",
                            }}
                            isAdding={true}
                            activeIndex={activeIndex}
                            index={item.challenges.length}
                            isEditing={isAdding}
                            isSelected={false}
                            ref={(el) =>
                              (cardRefs.current[item.challenges.length] = el)
                            }
                            onSubmitSuccess={handleFetchSubmit}
                          />
                        )}
                      </SliderContainer>
                      <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                              onClick={toggleIsAdding}>
                              Add Challenge & Communication Task Manually
                              <Icon
                                icon='lucide:plus'
                                className='w-20 h-20'
                              />
                            </button>
                          ) : (
                            <div></div>
                          ))}
                        <div className='flex justify-end items-center gap-x-15'>
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <Fragment>
                                <button
                                  className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }>
                                  <span className='text-15 font-semibold'>
                                    Cancel
                                  </span>
                                  <Icon
                                    icon='lucide:x'
                                    className='w-20 h-20'
                                  />
                                </button>
                                <button
                                  className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                                  onClick={handleSubmit}>
                                  <span className='text-15 font-semibold'>
                                    Save
                                  </span>
                                  <Icon
                                    icon='lucide:save'
                                    className='w-20 h-20'
                                  />
                                </button>
                              </Fragment>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className='flex w-full pb-60'>
                        {!isLoading && sources && (
                          <SourcesList sources={sources} />
                        )}
                        {isLoading && (
                          <div className='inline-flex gap-10'>
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div
                                key={index}
                                className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className='block'>
                <div className='w-full h-55 animate-pulse bg-soft-purple-redx rounded-8' />
                <div className='mt-20' />
                <SliderContainer
                  isEditing={isEditing}
                  isAdding={isAdding}
                  length={1}
                  selectedIndexes={selectedIndex}
                  goToIndex={goToIndex}
                  onActiveIndex={undefined}>
                  <Card
                    id={-1}
                    onSelectSuccess={onSelectSuccess}
                    project={project}
                    totalData={-1}
                    item={{
                      challenge: "",
                      communication_task: "",
                    }}
                    isFetching={true}
                    activeIndex={activeIndex}
                    index={-1}
                    isEditing={isAdding}
                    isSelected={false}
                    ref={(el) => (cardRefs.current[-1] = el)}
                    onSubmitSuccess={handleFetchSubmit}
                  />
                </SliderContainer>
                <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                  {!roles.includes("View Only") &&
                    (!isAdding && !isEditing ? (
                      <button
                        className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                        onClick={undefined}>
                        Add Challenge & Communication Task Manually
                        <Icon
                          icon='lucide:plus'
                          className='w-20 h-20'
                        />
                      </button>
                    ) : (
                      <div></div>
                    ))}
                  <div className='flex justify-end items-center gap-x-15'>
                    {!roles.includes("View Only") &&
                      (isEditing || isAdding ? (
                        <Fragment>
                          <button
                            className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>
                              Cancel
                            </span>
                            <Icon
                              icon='lucide:x'
                              className='w-20 h-20'
                            />
                          </button>
                          <button
                            disabled={false}
                            className={`inline-flex items-center gap-x-4 ${
                              false ? "text-gray-600" : "text-blue-redx"
                            } cursor-pointer`}
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>Save</span>
                            <Icon
                              icon='lucide:save'
                              className='w-20 h-20'
                            />
                          </button>
                        </Fragment>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className='flex w-full pb-60'>
                  <div className='inline-flex gap-10'>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerate}
        onClose={() => {
          setShowRegenerateModal(false);
        }}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
