import GenerateLoading from "@/Components/GenerateLoading";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { ProjectContext } from "@/Context/ProjectContext";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import useRolesBadge from "@/Hooks/useRolesBadge";

import { ProjectProps, SourcesType } from "@/Types/projects";
import { SectionList } from "@/Types/tabs";
import { progressPortion } from "@/Utils/dispatcher";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import SelectedCard from "@/Components/SelectedCard";
import SliderContainer from "@/Components/Projects/SliderContainer";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import SourcesList from "@/Components/Projects/SourcesList";
import EditButton from "@/Components/Projects/EditButton";
import Card from "./Partials/Card";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import { useQueryClient } from "@tanstack/react-query";
import { RouteOption } from "@/Context/reducer/projectReducer";
import SectionInformation from "@/Components/Projects/SectionInformation";
import {
  IdeaExpansion,
  IdeaExpansionContent,
  IdeaExpansionList,
  KeyTouchPointSummary,
} from "@/Types/idea-expansion";
import useIdeaExpansions from "@/Hooks/react-query/idea-expansion/useIdeaExpansions";
import SelectedKeyTouchPointCard from "./Partials/SelectedKeyTouchPointCard";
import { IconCheckMark } from "./Partials/IconCheckMark";
import { useSubmitIdeaExpansion } from "@/Hooks/react-query/idea-expansion/useSubmit";
import { Schema } from "@/Types/idea-expansion/schema";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [parsedData, setParsedData] = useState<IdeaExpansionList[]>([]);
  const [editedData, setEditedData] = useState<IdeaExpansionList[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const maxGenerateLimit = project.max_generated_data;
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [selectedArchetypeId, setSelectedArchetypeId] = useState<number>(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>("");
  const [selectedRecomNumber, setSelectedRecomNumber] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [isGenerate, setIsGenerate] = useState(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [isChangeFunnel, setIsChangeFunnel] = useState(false);

  const navigate = useNavigate();
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes("Owner") || roles.includes("Creative");
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabsArchetypeRef = useRef([]);
  const tabsCategoryTypeRef = useRef([]);
  const [selectedFramework, setSelectedFramework] = useState<string>("");
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const [totalArchetype, setTotalArchetype] = useState<number>(0);
  const [selectedIdeaExpansionContent, setSelectedIdeaExpansionContent] =
    useState<IdeaExpansion[]>();

  const { isIdeaExpansion, isUpdateState: isPendingHistory } = useHistoryStatus(
    project,
    state.isEnabledHistoryStatus,
  );

  const {
    isLoading,
    data,
    refetch: refetchTouchPoint,
  } = useIdeaExpansions(project.slug);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { handleGenerateIdeaExpansion } = useGenerate(dispatch);

  const { mutate, isPending: isSubmitting } = useSubmitIdeaExpansion();

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    resetState();
    handleGenerateIdeaExpansion()(project.slug, prompt);
  };

  const routeOptionValue: RouteOption = {
    label: "Next to Creative Idea",
    isActive: false,
    isGenerate: false,
    isInactive: true,
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));

    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/idea-personalization`),
      }),
    );
  }, []);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleNext = () => {
    if (activeTab < parsedData!.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      setSelectedArchetypeId(parsedData[nextIndex].id ?? 0);
      setSelectedFunnel(parsedData[nextIndex].idea_expansions[0].funnel ?? "");
      setSelectedRecomNumber(
        parsedData[nextIndex].idea_expansions[0].recommendation_number ?? 0,
      );
      if (
        tabsArchetypeRef.current[nextIndex] &&
        "scrollIntoView" in tabsArchetypeRef.current[nextIndex]
      ) {
        (tabsArchetypeRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      setSelectedArchetypeId(parsedData[prevIndex].id ?? 0);
      setSelectedFunnel(parsedData[prevIndex].idea_expansions[0].funnel ?? "");
      setSelectedRecomNumber(
        parsedData[prevIndex].idea_expansions[0].recommendation_number ?? 0,
      );
      if (
        tabsArchetypeRef.current[prevIndex] &&
        "scrollIntoView" in tabsArchetypeRef.current[prevIndex]
      ) {
        (tabsArchetypeRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: "Idea Expansion",
    value: "expansion",
    section: "idea",
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  useEffect(() => {
    if (data) {
      const { data: ideaExpansions, is_querying } = data;

      if (is_querying) {
        setIsGenerate(true);
        resetState();
        return;
      }

      if (ideaExpansions) {
        const { lists, selected_framework, total_regenerate, sources } =
          ideaExpansions;

        lists &&
          selectedArchetypeId === 0 &&
          setSelectedArchetypeId(lists[0].id ?? 0);

        if (
          lists &&
          (selectedFunnel === "" || selectedFramework !== selected_framework)
        ) {
          setSelectedFunnel(lists[0].idea_expansions[0]?.funnel ?? "");
          setSelectedRecomNumber(
            lists[0].idea_expansions[0]?.recommendation_number ?? 0,
          );
        }

        setParsedData(lists ?? []);
        setEditedData(lists ?? []);

        setSelectedFramework(selected_framework!);
        setTotalRegenerate(total_regenerate ?? 0);
        setSources(sources ?? []);

        setIsGenerate(false);
      }
    }

    const totalArchetype = data?.data?.total_archetype || 0;
    const totalSelectedPerArchetype =
      data?.data?.total_selected_per_archetype || 0;
    setTotalSelected(totalSelectedPerArchetype);
    setTotalArchetype(totalArchetype);
  }, [
    data,
    differentArchetypes?.is_different_archetype.idea_expansions,
    isIdeaExpansion,
  ]);

  useEffect(() => {
    if (!isIdeaExpansion.status) {
      dispatch(saveInformation(`0 Idea Expansion in 0 Archetype are selected`));
    }

    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.idea_expansions
    ) {
      if (!isGenerate && !isPendingHistory && !isIdeaExpansion.isQuerying) {
        setIsGenerate(true);
        resetState();
        handleGenerateIdeaExpansion()(project.slug);
      }
    }

    if (isIdeaExpansion.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    parsedData,
    isPendingHistory,
    isPendingDiffArch,
    isGenerate,
    differentArchetypes,
    isIdeaExpansion,
    selectedArchetypeId,
    selectedFunnel,
  ]);

  useEffect(() => {
    !isIdeaExpansion.isQuerying && !isPendingHistory && refetchTouchPoint();
    isIdeaExpansion.isQuerying && !isPendingHistory && setIsGenerate(true);
  }, [
    isIdeaExpansion,
    isGenerate,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.idea_expansions,
  ]);

  const handleTitleFunnel = (value: string) => {
    return value
      .replace(/\bcredible\b/g, "credibility")
      .replace(/\bpurchase\b/g, "purchasability")
      .replace(/able\b/g, "ability")
      .replace("_funnel", "")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSave = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    const hasMany = totalSelected > 1;
    dispatch(
      saveInformation(
        `${totalSelected} Idea Expansion in ${totalArchetype} Archetype is selected`,
      ),
    );
  }, [totalSelected, totalArchetype]);

  useEffect(() => {
    if (parsedData.length) {
      const keyTouchPointContent = (parsedData
        .find((item) => item.id === selectedArchetypeId)
        ?.idea_expansions?.find(
          (item) =>
            item.funnel === selectedFunnel &&
            item.recommendation_number === selectedRecomNumber,
        )?.content ?? []) as IdeaExpansion[];

      const selectedDataWithIndex = keyTouchPointContent
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => item.is_selected);

      if (selectedDataWithIndex) {
        const indices = selectedDataWithIndex.map(({ index }) => index);
        setSelectedIndex(indices);
      }

      setSelectedIdeaExpansionContent(keyTouchPointContent);

      const indexesWithZeroSelected = checkSelectedArchetype(parsedData);

      routeOptionValue.isInactive = !!indexesWithZeroSelected.length;

      if (isAdding || isEditing) {
        routeOptionValue.isInactive = true;
      }

      dispatch(saveRouteNext(routeOptionValue));
    }
  }, [
    parsedData,
    selectedArchetypeId,
    selectedFunnel,
    selectedRecomNumber,
    isAdding,
    isEditing,
  ]);

  const checkSelectedArchetype = (parsedData: IdeaExpansionList[]) => {
    const totalSelectedPerArchetype = parsedData.map((archetype) => {
      const totalSelected = archetype.idea_expansions.reduce(
        (total, touchPoint) => {
          const selectedInTouchPoint = touchPoint.content!.filter(
            (content) => content.is_selected,
          ).length;
          return total + selectedInTouchPoint;
        },
        0,
      );

      return {
        archetype_id: archetype.id,
        total_selected_touchpoints: totalSelected,
      };
    });

    return totalSelectedPerArchetype
      .map((_, index) =>
        totalSelectedPerArchetype[index].total_selected_touchpoints === 0
          ? index
          : -1,
      )
      .filter((index) => index !== -1);
  };

  const resetState = () => {
    setParsedData([]);
    setSources([]);
    setEditedData([]);
    dispatch(saveSubInformation(""));
    setSelectedArchetypeId(0);
    setSelectedFunnel("");
  };

  const handleSelectTab = (archetypeId: number) => {
    const selectedList = parsedData.find((item) => item.id === archetypeId);
    setSelectedFunnel(selectedList?.idea_expansions[0]?.funnel ?? "");
    setSelectedRecomNumber(
      selectedList?.idea_expansions[0]?.recommendation_number ?? 0,
    );

    setActiveCategoryTab(0);
    setSelectedArchetypeId(archetypeId);
  };

  const handleSubmit = (
    data: IdeaExpansionContent,
    id: number,
    index: number,
  ) => {
    const payload: Schema = {
      idea_title: data.idea_title!,
      campaign_example: data.campaign_example!,
      campaign_metrics: data.campaign_metrics!,
      idea_description: data.idea_description!,
      preferred_platform: data.preferred_platform!,
      preferred_influencer: data.preferred_influencer!,
      reason_product_brand: data.reason_product_brand!,
      reason_audience_archetype: data.reason_audience_archetype!,
    };

    mutate(
      {
        payload,
        id,
        recomNumber: selectedRecomNumber,
        archetypeId: selectedArchetypeId,
        funnel: selectedFunnel,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetchTouchPoint();
          }, 700);

          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }
        },
      },
    );
  };

  const formatSelectedSummaryKeyTouchPoint = (
    keyTouchPoints: KeyTouchPointSummary[],
  ): string => {
    const formattedSummary = keyTouchPoints.map((point) => {
      const label = handleTitleFunnel(point.funnel_name ?? ""); // Default to funnel_name if not mapped
      return `${label} (${point.total})`;
    });

    return formattedSummary.join(", ");
  };

  const reformatKeyTouchPoints = (data: KeyTouchPointSummary[]) => {
    return data.flatMap((point) => {
      return (
        point.details?.map((detail, index) => {
          return {
            title: `${handleTitleFunnel(point.funnel_name ?? "")} Touch Point ${
              detail.number ?? index
            }`,
            content: detail.value,
          };
        }) ?? []
      );
    });
  };

  return (
    <Fragment>
      {isGenerate ? (
        <>
          <GenerateLoading
            progress={progressPortion({
              progress: Number((state.ideaExpansion.progress / 2).toFixed(0)),
              isQuerying: isIdeaExpansion.isQuerying,
              isContentAvailable: parsedData!.length > 0,
            })}
            project={project}
            section={sectionList}
            estimateTimeFrom={4}
            estimateTimeTo={9}
            showEmailNotification={isIdeaExpansion.isSendingEmail}
          />
        </>
      ) : (
        <div className='w-full h-full'>
          <div className='flex items-center justify-between sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col w-fit'>
              <div className='text-25 font-bold mb-8 leading-none inline-flex items-center gap-12'>
                Idea Expansion
                <SectionInformation
                  title='What did Ainstein do with Idea Expansion?'
                  description='Ainstein expand your initial idea in the different touch points based on the selected framework'
                />
              </div>
              <span className='text-15 font-normal text-grey-redx'>
                Please{" "}
                <span className='text-black-redx font-bold'>
                  select one idea for each touch point and archetype
                </span>
                , edit, or add by clicking 'Add Idea Expansion Manually'.
              </span>
            </div>
            {isEditor && (
              // remove onSubmit={(form) => handleRegenerateButton(form.prompt)} for temporary
              // set limit with maxgeneratelimit
              <RegenerateButton
                isGenerateMore
                limit={0}
                onSubmit={(form) => form.prompt}
                maxLimit={0}
              />
            )}
          </div>

          <TabGroup
            className='pb-80 mt-35'
            selectedIndex={activeTab}
            onChange={setActiveTab}>
            <div className='relative flex items-center'>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  onClick={handlePrev}
                  className='p-2 text-gray-500 hover:text-black'
                  disabled={activeTab === 0}>
                  <Icon
                    icon='mingcute:left-line'
                    className='text-25'
                  />
                </button>
              )}
              <TabList className='flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200'>
                {!isLoading &&
                  parsedData.map((archetype, index) => (
                    <Tab
                      disabled={isEditing || isAdding}
                      ref={(el: HTMLElement | null) => {
                        if (el) {
                          (tabsArchetypeRef.current as HTMLElement[])[index] =
                            el;
                        }
                      }}
                      className='text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                      key={index}
                      onClick={() => handleSelectTab(archetype.id ?? 0)}>
                      <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                        <div className='flex flex-col gap-8'>
                          {`Archetype ${index + 1}`}
                          <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                            {archetype.archetype_content?.archetype_name ?? ""}
                          </div>
                        </div>
                        {errorTabIndex && errorTabIndex.includes(index) && (
                          <Icon
                            icon='mingcute:information-line'
                            className='text-25 text-error-redx'
                          />
                        )}
                      </div>
                    </Tab>
                  ))}

                {isLoading &&
                  Array.from({ length: 3 }).map((_, index) => (
                    <button
                      key={`dummy-${index}`}
                      className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                      <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                    </button>
                  ))}
              </TabList>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  onClick={handleNext}
                  className='p-2 text-gray-500 hover:text-black'
                  disabled={activeTab === parsedData!.length - 1}>
                  <Icon
                    icon='mingcute:right-line'
                    className='text-25'
                  />
                </button>
              )}
            </div>
            <TabPanels className={"py-24"}>
              {!isLoading ? (
                parsedData.map((archetype, index) => (
                  <TabPanel key={index}>
                    <div className='pt-5'>
                      <SelectedCard
                        title={`Archetype Detail - ${
                          archetype.archetype_content?.archetype_name ?? ""
                        }`}
                        data={[
                          {
                            title: "Archetype Name",
                            content:
                              archetype.archetype_content?.archetype_name ?? "",
                          },
                          {
                            title: "Demographic",
                            content:
                              archetype.archetype_content?.demographics ?? "",
                          },
                          {
                            title: "Occupation",
                            content:
                              archetype.archetype_content?.occupation ?? "",
                          },
                          {
                            title: "Lifestyle",
                            content:
                              archetype.archetype_content?.lifestyle ?? "",
                          },
                          {
                            title: "Behavior",
                            content:
                              archetype.archetype_content?.behavior ?? "",
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              archetype.archetype_content
                                ?.needs_and_challenges ?? "",
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              archetype.archetype_content
                                ?.potential_buying_motives ?? "",
                          },
                          {
                            title: "Current Trends",
                            content:
                              archetype.archetype_content?.current_trends ?? "",
                          },
                          {
                            title: "Source of Information",
                            content:
                              archetype.archetype_content
                                ?.source_of_information ?? "",
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              archetype.archetype_content
                                ?.online_search_behavior ?? "",
                          },
                          {
                            title: "Purchase Frequency",
                            content:
                              archetype.archetype_content?.purchase_frequency ??
                              "",
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              archetype.archetype_content
                                ?.preferred_sales_channels ?? "",
                          },
                        ]}
                      />
                      <div className='mt-24' />
                      <SelectedKeyTouchPointCard
                        archetypeId={archetype.id!}
                        title={`Selected Touch Point - ${
                          archetype.archetype_content?.archetype_name ?? ""
                        }`}
                        subtitle={formatSelectedSummaryKeyTouchPoint(
                          archetype?.key_touch_point_summary ?? [],
                        )}
                        data={reformatKeyTouchPoints(
                          archetype?.key_touch_point_summary ?? [],
                        ).map((item) => ({
                          ...item,
                          content: item.content ?? "",
                        }))}
                        projectSlug={project.slug}
                      />
                      <div className='mt-20' />

                      <TabGroup
                        defaultIndex={activeCategoryTab}
                        className='flex flex-row w-full gap-24'
                        selectedIndex={activeCategoryTab}
                        onChange={setActiveCategoryTab}>
                        <TabList className='w-2/3 border-r-1 border-r-stroke-redx flex flex-col justify-start items-start'>
                          {parsedData
                            .filter((item) => item.id === selectedArchetypeId)
                            .map((archetype, indexArchetype) => {
                              return archetype.idea_expansions.map(
                                (funnel, indexFunnel) => {
                                  const value = funnel?.total_selected ?? 0;

                                  return (
                                    <Tab
                                      disabled={isEditing || isAdding}
                                      key={`${indexArchetype}-${indexFunnel}`}
                                      className='w-full text-start py-10 text-16 leading-20 text-black-redx font-normal data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold'
                                      ref={(el: HTMLElement | null) => {
                                        if (el) {
                                          (
                                            tabsCategoryTypeRef.current as HTMLElement[]
                                          )[indexArchetype] = el;
                                        }
                                      }}
                                      onClick={() => {
                                        setSelectedFunnel(funnel!.funnel ?? "");
                                        setSelectedRecomNumber(
                                          funnel!.recommendation_number ?? 0,
                                        );
                                      }}>
                                      <p className='font-normal'>
                                        {`${handleTitleFunnel(
                                          funnel?.funnel ?? "",
                                        )} ${funnel?.recommendation_number}`}
                                      </p>
                                      {value > 0 ? (
                                        <p className='text-light-success-redx text-12 leading-14 font-semibold inline-flex gap-4'>
                                          Selected <IconCheckMark />
                                        </p>
                                      ) : (
                                        <p className='text-grey-redx text-12 leading-14 font-semibold'>
                                          unselected
                                        </p>
                                      )}
                                    </Tab>
                                  );
                                },
                              );
                            })}
                        </TabList>
                        <TabPanels className='flex flex-col grow w-4/5'>
                          <div
                            key={`id_${selectedFunnel}_${selectedRecomNumber}`}>
                            {selectedIdeaExpansionContent &&
                              selectedIdeaExpansionContent.length && (
                                <SliderContainer
                                  isEditing={isEditing}
                                  isAdding={isAdding}
                                  length={selectedIdeaExpansionContent.length}
                                  selectedIndexes={
                                    isChangeFunnel ? [] : selectedIndex
                                  }
                                  goToIndex={goToIndex}
                                  onActiveIndex={(index) =>
                                    setActiveIndex(index)
                                  }>
                                  {!isAdding &&
                                    selectedIdeaExpansionContent.map(
                                      (content, index) => (
                                        <div className='mt-10 mb-24'>
                                          <Card
                                            totalData={
                                              selectedIdeaExpansionContent.length
                                            }
                                            project={project}
                                            id={content.id ?? -1}
                                            selectedFramework={`${handleTitleFunnel(
                                              selectedFunnel,
                                            )} Touchpoint ${selectedRecomNumber}`}
                                            key={index}
                                            item={
                                              content.idea_expansion_content
                                            }
                                            index={index}
                                            isEdited={content.is_edit ?? false}
                                            isEditing={isEditing}
                                            isEditor={isEditor}
                                            isFetching={isChangeFunnel}
                                            onHandleSubmit={handleSubmit}
                                            isSelected={content.is_selected}
                                            ref={(el) =>
                                              (cardRefs.current[index] = el)
                                            }
                                            onSuccessSelected={() => {
                                              refetchTouchPoint();
                                            }}
                                          />
                                        </div>
                                      ),
                                    )}
                                  {isAdding && (
                                    <div className='my-24'>
                                      <Card
                                        totalData={
                                          selectedIdeaExpansionContent.length
                                        }
                                        project={project}
                                        id={-1}
                                        selectedFramework={`${handleTitleFunnel(
                                          selectedFunnel,
                                        )} Touchpoint ${selectedRecomNumber}`}
                                        key={0}
                                        item={{
                                          idea_title: "",
                                          campaign_example: "",
                                          campaign_metrics: "",
                                          idea_description: "",
                                          preferred_platform: "",
                                          preferred_influencer: "",
                                          reason_product_brand: "",
                                          reason_audience_archetype: "",
                                          idea_expansion_number: 0,
                                        }}
                                        index={
                                          selectedIdeaExpansionContent.length
                                        }
                                        isEditing={isEditing || isAdding}
                                        isEditor={isEditor}
                                        isFetching={isChangeFunnel}
                                        onHandleSubmit={handleSubmit}
                                        isSelected={false}
                                        ref={(el) => (cardRefs.current[0] = el)}
                                      />
                                    </div>
                                  )}
                                </SliderContainer>
                              )}
                          </div>

                          <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                            {isEditor &&
                              (!isAdding && !isEditing ? (
                                <button
                                  className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                                  onClick={toggleIsAdding}>
                                  Add Idea Expansion Manually
                                  <Icon
                                    icon='lucide:plus'
                                    className='w-20 h-20'
                                  />
                                </button>
                              ) : (
                                <div></div>
                              ))}
                            <div className='flex justify-end items-center gap-x-15'>
                              {isEditor &&
                                (isEditing || isAdding ? (
                                  <Fragment>
                                    <button
                                      className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                      onClick={
                                        isAdding
                                          ? toggleIsAdding
                                          : handleCancelEdit
                                      }>
                                      <span className='text-15 font-semibold'>
                                        Cancel
                                      </span>
                                      <Icon
                                        icon='lucide:x'
                                        className='w-20 h-20'
                                      />
                                    </button>
                                    <button
                                      disabled={isSubmitting}
                                      className={`inline-flex items-center gap-x-4 ${
                                        isSubmitting
                                          ? "text-placeholder-redx"
                                          : "text-blue-redx"
                                      } cursor-pointer`}
                                      onClick={handleSave}>
                                      <span className='text-15 font-semibold'>
                                        Save
                                      </span>
                                      <Icon
                                        icon='lucide:save'
                                        className='w-20 h-20'
                                      />
                                    </button>
                                  </Fragment>
                                ) : (
                                  <EditButton toggleEditing={toggleIsEditing} />
                                ))}
                            </div>
                          </div>
                          <div className='flex w-full pb-60'>
                            {!isLoading && sources && (
                              <SourcesList sources={sources} />
                            )}

                            {isLoading && (
                              <div className='inline-flex gap-10'>
                                {Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                    key={index}
                                    className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </TabPanels>
                      </TabGroup>
                    </div>
                  </TabPanel>
                ))
              ) : (
                <TabPanel>
                  <div className='pt-5'>
                    <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                    <div className='mt-20' />

                    <TabGroup
                      className='flex flex-row w-full gap-24'
                      selectedIndex={activeCategoryTab}
                      onChange={setActiveCategoryTab}>
                      <TabList className='w-2/3 border-r-1 border-r-stroke-redx flex flex-col justify-start items-start'>
                        {Array.from({ length: 3 }).map((_, index) => {
                          return (
                            <Tab
                              key={index}
                              className='w-full text-start py-10 text-16 leading-20 text-black-redx font-normal data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold'
                              ref={(el: HTMLElement | null) => {
                                if (el) {
                                  (
                                    tabsCategoryTypeRef.current as HTMLElement[]
                                  )[index] = el;
                                }
                              }}>
                              <p className='font-normal'>
                                <div className='w-2/3 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                              </p>
                              <p className='text-grey-redx text-12 leading-14 mt-10'>
                                <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                              </p>
                            </Tab>
                          );
                        })}
                      </TabList>
                      <TabPanels className='flex flex-col grow w-4/5'>
                        <div className='-mt-24'>
                          <SliderContainer
                            isEditing={false}
                            isAdding={false}
                            length={3}
                            selectedIndexes={[]}
                            goToIndex={goToIndex}
                            onActiveIndex={(index) => setActiveIndex(index)}>
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div className='my-24'>
                                <Card
                                  project={project}
                                  index={-1}
                                  id={-1}
                                  selectedFramework=''
                                  key={index}
                                  isFetching={true}
                                  item={{
                                    idea_title: "",
                                    campaign_example: "",
                                    campaign_metrics: "",
                                    idea_description: "",
                                    preferred_platform: "",
                                    preferred_influencer: "",
                                    reason_product_brand: "",
                                    reason_audience_archetype: "",
                                    idea_expansion_number: 0,
                                  }}
                                  isEditor={false}
                                />
                              </div>
                            ))}
                          </SliderContainer>
                        </div>

                        <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                                onClick={toggleIsAdding}>
                                Add {handleTitleFunnel(selectedFunnel)}{" "}
                                Touchpoint Manually
                                <Icon
                                  icon='lucide:plus'
                                  className='w-20 h-20'
                                />
                              </button>
                            ) : (
                              <div></div>
                            ))}
                          <div className='flex justify-end items-center gap-x-15'>
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <Fragment>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }>
                                    <span className='text-15 font-semibold'>
                                      Cancel
                                    </span>
                                    <Icon
                                      icon='lucide:x'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                                    onClick={() => {}}>
                                    <span className='text-15 font-semibold'>
                                      Save
                                    </span>
                                    <Icon
                                      icon='lucide:save'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                </Fragment>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className='flex w-full pb-60'>
                          {!isLoading && sources && (
                            <SourcesList sources={sources} />
                          )}

                          {isLoading && (
                            <div className='inline-flex gap-10'>
                              {Array.from({ length: 3 }).map((_, index) => (
                                <div
                                  key={index}
                                  className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </TabPanel>
              )}
            </TabPanels>
          </TabGroup>
        </div>
      )}
    </Fragment>
  );
};

export default Index;
