import { Fragment, useEffect, forwardRef, useImperativeHandle } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProjectProps } from "@/Types/projects";
import { CardHandles } from "../Index";
import { IdeaExpansionContent } from "@/Types/idea-expansion";
import { useUpdateSelectedIdeaExpansion } from "@/Hooks/react-query/idea-expansion/useUpdateSelected";
import _ from "lodash";
import CardInput from "@/Components/Projects/SliderCard/CardInput";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import { IdeaExpansionSchema } from "@/Types/idea-expansion/schema";

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    index: number;
    project: ProjectProps;
    item?: IdeaExpansionContent;
    isSelected?: boolean;
    isEditing?: boolean;
    isEdited?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    selectedFramework: string;
    totalData?: number;
    onSuccessSelected?: () => void;
    onHandleSubmit?: (
      data: IdeaExpansionContent,
      id: number,
      index: number,
    ) => void;
  }
>(
  (
    {
      id,
      index,
      project,
      item,
      selectedFramework,
      onHandleSubmit,
      isEditing = false,
      isEditor,
      isFetching = false,
      isEdited = false,
      isSelected = false,
      totalData = 0,
      onSuccessSelected,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaExpansion();

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<IdeaExpansionContent>({
      resolver: zodResolver(IdeaExpansionSchema),
      defaultValues: {
        idea_title: item?.idea_title ?? "",
        campaign_example: item?.campaign_example ?? "",
        campaign_metrics: item?.campaign_metrics ?? "",
        idea_description: item?.idea_description ?? "",
        preferred_platform: item?.preferred_platform ?? "",
        preferred_influencer: item?.preferred_influencer ?? "",
        reason_product_brand: item?.reason_product_brand ?? "",
        idea_expansion_number: item?.idea_expansion_number ?? 0,
        reason_audience_archetype: item?.reason_audience_archetype ?? "",
      },
    });

    useEffect(() => {
      setValue("idea_title", item?.idea_title ?? "");
      setValue("campaign_example", item?.campaign_example ?? "");
      setValue("campaign_metrics", item?.campaign_metrics ?? "");
      setValue("idea_description", item?.idea_description ?? "");
      setValue("preferred_platform", item?.preferred_platform ?? "");
      setValue("preferred_influencer", item?.preferred_influencer ?? "");
      setValue("reason_product_brand", item?.reason_product_brand ?? "");
      setValue(
        "reason_audience_archetype",
        item?.reason_audience_archetype ?? "",
      );
      setValue("idea_expansion_number", item?.idea_expansion_number ?? 0);
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: IdeaExpansionContent) => {
      onHandleSubmit && onHandleSubmit(data, id, index);
    };

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSuccessSelected && onSuccessSelected();
          },
        },
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          id === -1 ? (
            <div className='inline-flex items-center justify-center w-full gap-8 font-semibold'>
              {selectedFramework}
              <div className='w-1 h-20 border-1 border-stroke-redx' />
              <p>
                Idea Expansion {index + 1} of {totalData + 1}
              </p>
            </div>
          ) : (
            <div className='inline-flex items-center justify-center w-full gap-8 font-semibold'>
              {selectedFramework}
              <div className='w-1 h-20 border-1 border-stroke-redx' />
              <p>
                Idea Expansion {index + 1} of {totalData}
              </p>
              {isEdited && (
                <div className='bg-soft-grey-redx px-8 py-4 text-grey-redx text-12 leading-14 rounded-8'>
                  Edited
                </div>
              )}
            </div>
          )
        ) : (
          <div className='inline-flex items-center justify-center w-full gap-8'>
            <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
            <div className='w-1 h-20 border-1 border-stroke-redx' />
            <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
          </div>
        )}

        <div
          className={`flex flex-col w-full gap-y-8 text-black-redx py-20 mt-10 px-18 ${
            isEditing && isSelected ? "!bg-transparent" : ""
          }
        ${
          isSelected && !isFetching
            ? "border-t-2 border-blue-redx bg-softer-purple-redx"
            : "border-t-1 border-stroke-redx"
        }`}>
          <div className={`grid grid-cols-2 gap-x-24 gap-y-15 pb-8`}>
            <Fragment>
              <Controller
                control={control}
                name='idea_title'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.idea_title?.message}
                    placeholder='Input the idea title here'
                    title={"Idea Title"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='idea_description'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.idea_description?.message}
                    placeholder='Input the idea description here'
                    title={"Idea Description"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='reason_product_brand'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.reason_product_brand?.message}
                    placeholder='Input the reason product brand here'
                    title={"Reason Product Brand"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='reason_audience_archetype'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.reason_audience_archetype?.message}
                    placeholder='Input the reason target archetype here'
                    title={"Reason Audience Archetype"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='campaign_example'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.campaign_example?.message}
                    placeholder='Input the campaign example here'
                    title={"Campaign Example"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='campaign_metrics'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.campaign_metrics?.message}
                    placeholder='Input the campaign metrics here'
                    title={"Campaign Metrics"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='preferred_influencer'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.preferred_influencer?.message}
                    placeholder='Input the preferred influencer here'
                    title={"Preferred Influencer"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='preferred_platform'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.preferred_platform?.message}
                    placeholder='Input the preferred platform here'
                    title={"Preferred Platform"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
            </Fragment>
          </div>
        </div>

        <div className='flex w-full justify-end px-1 py-15'>
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              index={index}
              isLoading={isUpdatingSelected}
              isEditing={isEditing}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
