import React from "react";

import ModalCenter from "@/Components/Modals/Center";

const RegenerateModal: React.FC<{
  isRegenerating: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}> = ({ isOpen, isRegenerating, onClose, handleSubmit }) => {
  const btnDisabledClass = isRegenerating
    ? "bg-placeholder-redx border-placeholder-redx cursor-not-allowed"
    : `bg-blue-redx border-blue-redx cursor-pointer`;

  return (
    <ModalCenter
      isOpen={isOpen}
      onClose={onClose}>
      <div className={`relative w-full max-w-xl`}>
        <div className='flex transition-transform transform duration-500'>
          <div className='w-full flex-shrink-0 py-50 px-40 md:py-50 md:px-30'>
            <div className='mb-40'>
              <h1 className='font-semibold text-30 text-black-redx mb-16 text-center'>
                Your Previous Results Will Be Lost
              </h1>
              <p className='text-grey-redx text-15 text-center'>
                Modifying this section will impact other related sections. The
                system will regenerate and your previous output cannot be
                recovered.
              </p>
            </div>
            <div className='flex flex-col w-full gap-y-15'>
              <button
                disabled={isRegenerating}
                className={`w-full py-15 ${btnDisabledClass} font-semibold rounded-10 text-white`}
                onClick={handleSubmit}>
                {isRegenerating ? "Loading..." : "Proceed Anyway"}
              </button>
              <button
                className='w-full py-15 bg-transparent border-blue-redx border-2 text-blue-redx font-semibold rounded-10'
                onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default RegenerateModal;
