import GenerateLoading from "@/Components/GenerateLoading";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { ProjectContext } from "@/Context/ProjectContext";
import useKeyTouchPoint from "@/Hooks/react-query/key-touch-point/useKeyTouchPoint";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import useRolesBadge from "@/Hooks/useRolesBadge";
import {
  ArchetypeKeyTouchPointData,
  Content,
  Recommendation,
  RecommendationContent,
  SelectedFramework,
} from "@/Types/key_touch_point";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { SectionList } from "@/Types/tabs";
import { progressPortion } from "@/Utils/dispatcher";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectedFrameworkCard from "./Partials/SelectedFrameworkCard";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import SelectedCard from "@/Components/SelectedCard";
import SliderContainer from "@/Components/Projects/SliderContainer";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import SelectingFunnel from "./Partials/SelectingFunnel";
import SourcesList from "@/Components/Projects/SourcesList";
import EditButton from "@/Components/Projects/EditButton";
import Card from "./Partials/Card";
import { useUpdateKeyTouchPoint } from "@/Hooks/react-query/key-touch-point/useUpdateKeyTouchPoint";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import { useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { RouteOption } from "@/Context/reducer/projectReducer";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import { useSearchParams } from "react-router-dom";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [searchParams] = useSearchParams();
  const archetype = searchParams.get("archetype");
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [parsedData, setParsedData] = useState<ArchetypeKeyTouchPointData[]>(
    [],
  );
  const [editedData, setEditedData] = useState<ArchetypeKeyTouchPointData[]>(
    [],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const maxGenerateLimit = project.max_generated_data;
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [selectedArchetypeId, setSelectedArchetypeId] = useState<number>(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>("");
  const [selectedFramework, setSelectedFramework] = useState<SelectedFramework>(
    project.submissions[0].selected_framework,
  );
  const [selectedKeyTouchPointContent, setSelectedKeyTouchPointContent] =
    useState<Content[]>();
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const navigate = useNavigate();
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const { roles } = useRolesBadge(project, user);
  const isEditor =
    roles.includes("Owner") ||
    roles.includes("Strategist") ||
    roles.includes("Creative");

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabsArchetypeRef = useRef([]);
  const tabsCategoryTypeRef = useRef([]);
  const [totalSelectedKeyTouchPoint, setTotalSelectedKeyTouchPoint] =
    useState<number>(0);
  const [totalSelectedArchetype, setTotalSelectedArchetype] =
    useState<number>(0);
  const [totalArchetype, setTotalArchetype] = useState<number>(0);

  const {
    isKeyTouchPoint,
    isIdeaExpansion,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const {
    isLoading,
    data,
    refetch: refetchTouchPoint,
  } = useKeyTouchPoint(project.slug);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { handleGenerateKeyTouchPoint, handleGenerateIdeaExpansion } =
    useGenerate(dispatch);

  const { mutate, isPending: isSubmitting } = useUpdateKeyTouchPoint();

  const handleSelectedFramework = (value: string) => {
    if (value === "marketing_funnel") return "Marketing Funnel";
    else return "S.D.R.C.P";
  };

  const handleRegenerateButton = async (prompt: string) => {
    setIsGenerate(true);
    resetState();
    await handleGenerateKeyTouchPoint()(project.slug, prompt);
  };

  const routeOptionValue: RouteOption = {
    label: "Next to Creative Idea",
    isActive: true,
    isGenerate: false,
    isInactive: true,
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));

    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/one-page-strategy`),
      }),
    );
    const newFramework = Cookies.get("selected_framework");
    setSelectedFramework(
      (newFramework as SelectedFramework) === undefined
        ? project.submissions[0].selected_framework
        : (newFramework as SelectedFramework),
    );
  }, []);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleNext = () => {
    if (activeTab < parsedData!.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      setSelectedArchetypeId(parsedData[nextIndex].id);
      setSelectedFunnel(parsedData[nextIndex].key_touch_points[0].funnel ?? "");
      if (
        tabsArchetypeRef.current[nextIndex] &&
        "scrollIntoView" in tabsArchetypeRef.current[nextIndex]
      ) {
        (tabsArchetypeRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      setSelectedArchetypeId(parsedData[prevIndex].id);
      setSelectedFunnel(parsedData[prevIndex].key_touch_points[0].funnel ?? "");
      if (
        tabsArchetypeRef.current[prevIndex] &&
        "scrollIntoView" in tabsArchetypeRef.current[prevIndex]
      ) {
        (tabsArchetypeRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: "Key Touch Point",
    value: selectedFramework,
    section: "key_touch_point",
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  useEffect(() => {
    const totalArchetype = data?.total_archetype || 0;
    const totalSelected = data?.total_selected || 0;
    const totalSelectedPerArchetype = data?.total_selected_per_archetype || 0;
    setTotalSelectedArchetype(totalSelectedPerArchetype);
    setTotalSelectedKeyTouchPoint(totalSelected);
    setTotalArchetype(totalArchetype);

    if (data) {
      const {
        data: keyTouchPoints,
        sources,
        total_regenerate,
        selected_framework,
        is_querying,
      } = data;

      if (is_querying) {
        setIsGenerate(true);
        setParsedData([]);
        setEditedData([]);
        setSelectedFramework(project.submissions[0].selected_framework);
        setSelectedArchetypeId(0);
        setSelectedFunnel("");
        return;
      }

      setParsedData(keyTouchPoints);
      setEditedData(keyTouchPoints);

      (selectedFramework !== selected_framework || selectedArchetypeId === 0) &&
        setSelectedArchetypeId(keyTouchPoints[0]?.id ?? 0);

      if (selectedFramework !== selected_framework || selectedFunnel === "") {
        setSelectedFunnel(keyTouchPoints[0]?.key_touch_points[0]?.funnel ?? "");
        setActiveCategoryTab(0);
      }

      setTotalRegenerate(total_regenerate);
      setSources(sources);

      setSelectedFramework(selected_framework);

      dispatch(saveSubInformation(""));
      setIsGenerate(false);
    }
  }, [
    data,
    isKeyTouchPoint,
    differentArchetypes?.is_different_archetype.key_touch_points,
  ]);

  useEffect(() => {
    if (!isKeyTouchPoint.status) {
      dispatch(saveInformation(`0 Touchpoint in 0 Archetype are selected`));
    }

    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.key_touch_points
    ) {
      if (!isGenerate && !isPendingHistory && !isKeyTouchPoint.isQuerying) {
        setIsGenerate(true);
        resetState();
        handleGenerateKeyTouchPoint()(project.slug);
      }
    }

    if (isKeyTouchPoint.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isPendingHistory,
    isPendingDiffArch,
    isGenerate,
    differentArchetypes,
    isKeyTouchPoint,
  ]);

  useEffect(() => {
    !isKeyTouchPoint.isQuerying && !isPendingHistory && refetchTouchPoint();

    isKeyTouchPoint.isQuerying && !isPendingHistory && setIsGenerate(true);
  }, [
    isGenerate,
    isKeyTouchPoint,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.key_touch_points,
  ]);

  useEffect(() => {
    if (parsedData.length) {
      setSelectedIndex([]);
      const keyTouchPointContent =
        parsedData
          .find((item) => item.id === selectedArchetypeId)
          ?.key_touch_points?.find((item) => item.funnel === selectedFunnel)
          ?.content ?? [];

      const selectedDataWithIndex = keyTouchPointContent
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => item.is_selected === 1);

      if (selectedDataWithIndex.length) {
        const indices = selectedDataWithIndex.map(({ index }) => index);
        setSelectedIndex(indices);

        if (errorTabIndex && errorTabIndex.length) {
          const updatedErrorTabIndex =
            errorTabIndex.filter((tabIndex) => tabIndex !== activeTab) ?? [];

          const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
            .map((index) => `Archetype ${index + 1}`)
            .join(", ");

          dispatch(saveSubInformation(""));

          namesWithoutSelectedKeyInsight &&
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please select Key touch point for for ${namesWithoutSelectedKeyInsight}`,
              ),
            );

          setErrorTabIndex(updatedErrorTabIndex);
        }
      }

      setSelectedKeyTouchPointContent(keyTouchPointContent);
    }

    routeOptionValue.isInactive =
      isEditor &&
      (isGenerate ||
        isKeyTouchPoint.isQuerying ||
        !isKeyTouchPoint.status ||
        totalSelectedArchetype < totalArchetype);

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        label:
          isEditor && isIdeaExpansion.isRegenerated
            ? "Regenerate Idea Expansion"
            : "Next to Creative Idea",
        isGenerate: isEditor ? isIdeaExpansion.isRegenerated : false,
        onClick: () => {
          if (
            !isKeyTouchPoint.status ||
            isGenerate ||
            isKeyTouchPoint.isQuerying
          ) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, key touch point is still generating`,
              ),
            );
            return;
          }

          if (isEditor) {
            if (isAdding || isEditing) {
              dispatch(
                saveSubInformation(`{text-error-redx} Please do save first`),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(""));
              }, 3000);
              return;
            }

            const hasError = hasSelectedKeyTouchPoint();

            if (!hasError) {
              if (isIdeaExpansion.isRegenerated) {
                setShowRegenerateModal(true);
                return;
              }

              navigate(`/${project.slug}/creative-idea`);
            }
            return;
          }
          navigate(`/${project.slug}/creative-idea`);
        },
      }),
    );
  }, [
    isAdding,
    isEditing,
    parsedData,
    selectedArchetypeId,
    selectedFunnel,
    isKeyTouchPoint,
    isGenerate,
    totalArchetype,
    totalSelectedArchetype,
    isIdeaExpansion,
    isEditor,
    isAdding,
    isEditing,
  ]);

  const handleTitleFunnel = (value: string) => {
    return value
      .replace(/\bcredible\b/g, "credibility")
      .replace(/\bpurchase\b/g, "purchasability")
      .replace(/able\b/g, "ability")
      .replace("_funnel", "")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSubmit = (data: Recommendation, id: number, index: number) => {
    const payload = {
      recommendation: {
        ...data,
      },
    } as RecommendationContent;

    mutate(
      {
        payload,
        id,
        archetypeId: selectedArchetypeId,
        funnel: selectedFunnel,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetchTouchPoint();
          }, 700);

          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }
        },
      },
    );
  };

  const handleSave = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleChangeFramework = (data: any) => {
    const newFramework = Cookies.get("selected_framework");
    if ((newFramework as SelectedFramework) !== undefined) {
      setSelectedFramework(newFramework as SelectedFramework);
    }

    setModalFramework(false);
    resetState();

    if (data.need_generate) {
      setIsGenerate(true);
      handleGenerateKeyTouchPoint()(project.slug);
    } else {
      queryClient.resetQueries({
        queryKey: ["keyTouchPoint", project.slug],
        exact: true,
      });

      refetchTouchPoint();
    }
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    if (archetype) {
      const archetypeId = Number(archetype);
      if (!isNaN(archetypeId)) {
        const index = parsedData.findIndex((item) => item.id === archetypeId);
        const filterData = parsedData[index];
        if (filterData) {
          setSelectedArchetypeId(filterData.id);
          setSelectedFunnel(filterData.key_touch_points[0]?.funnel ?? "");
          setActiveTab(index);
          if (
            tabsArchetypeRef.current[index] &&
            "scrollIntoView" in tabsArchetypeRef.current[index]
          ) {
            (tabsArchetypeRef.current[index] as Element).scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "center",
            });
          }
        }
      }
    }
  }, [archetype, parsedData]);

  useEffect(() => {
    const hasMany = totalSelectedKeyTouchPoint > 1;
    dispatch(
      saveInformation(
        `${totalSelectedKeyTouchPoint} ${
          hasMany ? "Touchpoints" : "Touchpoint"
        }  in ${totalArchetype} Archetype are selected`,
      ),
    );
  }, [totalSelectedKeyTouchPoint, totalArchetype]);

  const resetState = () => {
    setParsedData([]);
    setEditedData([]);
    dispatch(saveSubInformation(""));
    setSelectedArchetypeId(0);
    setSelectedFunnel("");
    setSelectedKeyTouchPointContent([]);
  };

  const hasSelectedKeyTouchPoint = () => {
    const indexesWithZeroSelected = checkSelectedArchetype();

    if (indexesWithZeroSelected.length) {
      setErrorTabIndex(indexesWithZeroSelected);
      const namesWithoutSelectedKeyInsight = indexesWithZeroSelected
        .map((item) => `Archetype ${item + 1}`)
        .join(", ");

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Key touch point for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    dispatch(saveSubInformation(""));
    await handleGenerateIdeaExpansion()(project.slug, null, "regenerate");
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-expansion`);
  };

  const checkSelectedArchetype = () => {
    const totalSelectedPerArchetype = parsedData.map((archetype) => {
      const totalSelected = archetype.key_touch_points.reduce(
        (total, touchPoint) => {
          const selectedInTouchPoint = touchPoint.content!.filter(
            (content) => content.is_selected === 1,
          ).length;
          return total + selectedInTouchPoint;
        },
        0,
      );

      return {
        archetype_id: archetype.id,
        total_selected_touchpoints: totalSelected,
      };
    });

    return totalSelectedPerArchetype
      .map((_, index) =>
        totalSelectedPerArchetype[index].total_selected_touchpoints === 0
          ? index
          : -1,
      )
      .filter((index) => index !== -1);
  };

  return (
    <Fragment>
      {isGenerate ? (
        <>
          <GenerateLoading
            progress={progressPortion({
              progress: Number((state.keyTouchPoint.progress / 2).toFixed(0)),
              isQuerying: isKeyTouchPoint.isQuerying,
              isContentAvailable: parsedData!.length > 0,
            })}
            project={project}
            section={sectionList}
            estimateTimeFrom={2}
            estimateTimeTo={6}
            showEmailNotification={isKeyTouchPoint.isSendingEmail}
          />
        </>
      ) : (
        <div className='w-full h-full'>
          <div className='flex items-center justify-between sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col w-fit'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Key Touch Point - {handleSelectedFramework(selectedFramework)}
              </h1>
              <span className='text-15 font-normal text-grey-redx'>
                Please select{" "}
                <span className='text-black-redx font-bold'>one or more</span>{" "}
                touchpoint for{" "}
                <span className='text-black-redx font-bold'>
                  each archetype,{" "}
                </span>
                or add by clicking 'Add Touchpoint Manually'.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          <SelectedFrameworkCard
            selectedFramework={handleSelectedFramework(selectedFramework)}
            onChangeFramework={() => setModalFramework(true)}
          />

          <TabGroup
            className='pb-80 mt-35'
            selectedIndex={activeTab}
            onChange={setActiveTab}>
            <div className='relative flex items-center'>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  onClick={handlePrev}
                  className='p-2 text-gray-500 hover:text-black'
                  disabled={activeTab === 0}>
                  <Icon
                    icon='mingcute:left-line'
                    className='text-25'
                  />
                </button>
              )}
              <TabList className='flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200'>
                {!isLoading &&
                  parsedData.map((archetype, index) => (
                    <Tab
                      disabled={isEditing || isAdding}
                      ref={(el: HTMLElement | null) => {
                        if (el) {
                          (tabsArchetypeRef.current as HTMLElement[])[index] =
                            el;
                        }
                      }}
                      className='text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                      key={index}
                      onClick={() => setSelectedArchetypeId(archetype.id)}>
                      <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                        <div className='flex flex-col gap-8'>
                          {`Archetype ${index + 1}`}
                          <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                            {archetype?.archetype_content?.archetype_name}
                          </div>
                        </div>
                        {errorTabIndex && errorTabIndex.includes(index) && (
                          <Icon
                            icon='mingcute:information-line'
                            className='text-25 text-error-redx'
                          />
                        )}
                      </div>
                    </Tab>
                  ))}

                {isLoading &&
                  Array.from({ length: 3 }).map((_, index) => (
                    <button
                      key={`dummy-${index}`}
                      className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                      <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                    </button>
                  ))}
              </TabList>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  onClick={handleNext}
                  className='p-2 text-gray-500 hover:text-black'
                  disabled={activeTab === parsedData!.length - 1}>
                  <Icon
                    icon='mingcute:right-line'
                    className='text-25'
                  />
                </button>
              )}
            </div>
            <TabPanels className={"py-24"}>
              {!isLoading ? (
                parsedData.map((archetype, index) => (
                  <TabPanel key={index}>
                    <div className='pt-5'>
                      <SelectedCard
                        title={`Archetype Detail - ${archetype.archetype_content?.archetype_name}`}
                        data={[
                          {
                            title: "Archetype Name",
                            content:
                              archetype.archetype_content?.archetype_name,
                          },
                          {
                            title: "Demographic",
                            content: archetype.archetype_content?.demographics,
                          },
                          {
                            title: "Occupation",
                            content: archetype.archetype_content?.occupation,
                          },
                          {
                            title: "Lifestyle",
                            content: archetype.archetype_content?.lifestyle,
                          },
                          {
                            title: "Behavior",
                            content: archetype.archetype_content?.behavior,
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              archetype.archetype_content?.needs_and_challenges,
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              archetype.archetype_content
                                ?.potential_buying_motives,
                          },
                          {
                            title: "Current Trends",
                            content:
                              archetype.archetype_content?.current_trends,
                          },
                          {
                            title: "Source of Information",
                            content:
                              archetype.archetype_content
                                ?.source_of_information,
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              archetype.archetype_content
                                ?.online_search_behavior,
                          },
                          {
                            title: "Purchase Frequency",
                            content:
                              archetype.archetype_content?.purchase_frequency,
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              archetype.archetype_content
                                ?.preferred_sales_channels,
                          },
                        ]}
                      />
                      <div className='mt-20' />

                      <TabGroup
                        defaultIndex={activeCategoryTab}
                        className='flex flex-row w-full gap-24'
                        selectedIndex={activeCategoryTab}
                        onChange={setActiveCategoryTab}>
                        <TabList className='w-2/3 border-r-1 border-r-stroke-redx flex flex-col justify-start items-start'>
                          {parsedData
                            .filter((item) => item.id === selectedArchetypeId)
                            .map((archetype, indexArchetype) => {
                              return archetype.key_touch_points.map(
                                (funnel, indexFunnel) => {
                                  const value =
                                    funnel?.total_selected_touchpoint ?? 0;

                                  return (
                                    <Tab
                                      disabled={isEditing || isAdding}
                                      key={`${indexArchetype}-${indexFunnel}`}
                                      className='w-full text-start py-10 text-16 leading-20 text-black-redx font-normal data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold'
                                      ref={(el: HTMLElement | null) => {
                                        if (el) {
                                          (
                                            tabsCategoryTypeRef.current as HTMLElement[]
                                          )[indexArchetype] = el;
                                        }
                                      }}
                                      onClick={() => {
                                        setSelectedFunnel(funnel!.funnel ?? "");
                                      }}>
                                      <p className='font-normal'>
                                        {handleTitleFunnel(
                                          funnel?.funnel ?? "",
                                        )}
                                      </p>
                                      <p className='text-grey-redx text-12 leading-14'>
                                        {value} selected
                                      </p>
                                    </Tab>
                                  );
                                },
                              );
                            })}
                        </TabList>
                        <TabPanels className='flex flex-col grow w-4/5'>
                          <div key={selectedFunnel}>
                            {selectedKeyTouchPointContent &&
                              selectedKeyTouchPointContent.length && (
                                <SliderContainer
                                  isEditing={isEditing}
                                  isAdding={isAdding}
                                  length={selectedKeyTouchPointContent.length}
                                  selectedIndexes={selectedIndex}
                                  goToIndex={goToIndex}
                                  onActiveIndex={(index) =>
                                    setActiveIndex(index)
                                  }>
                                  {!isAdding &&
                                    selectedKeyTouchPointContent.map(
                                      (content, index) => (
                                        <div className='mt-10 mb-24'>
                                          <Card
                                            totalData={
                                              selectedKeyTouchPointContent.length
                                            }
                                            project={project}
                                            id={content.id ?? -1}
                                            selectedFramework={
                                              selectedFramework ===
                                              "marketing_funnel"
                                                ? `${handleTitleFunnel(
                                                    selectedFunnel,
                                                  )} funnel`
                                                : handleTitleFunnel(
                                                    selectedFunnel,
                                                  )
                                            }
                                            key={index}
                                            item={
                                              content.recommendation_content
                                                ?.recommendation!
                                            }
                                            index={index}
                                            isEditing={isEditing}
                                            isEditor={isEditor}
                                            isFetching={false}
                                            onHandleSubmit={handleSubmit}
                                            isSelected={
                                              content.is_selected === 1
                                            }
                                            ref={(el) =>
                                              (cardRefs.current[index] = el)
                                            }
                                            onSuccessSelected={() => {
                                              refetchTouchPoint();
                                            }}
                                          />
                                        </div>
                                      ),
                                    )}
                                  {isAdding && (
                                    <div className='my-24'>
                                      <Card
                                        project={project}
                                        id={-1}
                                        selectedFramework={
                                          selectedFramework ===
                                          "marketing_funnel"
                                            ? `${handleTitleFunnel(
                                                selectedFunnel,
                                              )} funnel`
                                            : handleTitleFunnel(selectedFunnel)
                                        }
                                        key={0}
                                        item={{
                                          reason: "",
                                          media_types: "",
                                          idea_example: "",
                                          success_measurement: "",
                                          digital_recommendation: "",
                                          keyword: "",
                                          trending_topics: "",
                                        }}
                                        index={
                                          selectedKeyTouchPointContent.length
                                        }
                                        isEditing={isEditing || isAdding}
                                        isEditor={isEditor}
                                        isFetching={false}
                                        onHandleSubmit={handleSubmit}
                                        isSelected={false}
                                        ref={(el) => (cardRefs.current[0] = el)}
                                      />
                                    </div>
                                  )}
                                </SliderContainer>
                              )}
                          </div>

                          <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                            {isEditor &&
                              (!isAdding && !isEditing ? (
                                <button
                                  className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                                  onClick={toggleIsAdding}>
                                  Add {handleTitleFunnel(selectedFunnel)}{" "}
                                  Touchpoint Manually
                                  <Icon
                                    icon='lucide:plus'
                                    className='w-20 h-20'
                                  />
                                </button>
                              ) : (
                                <div></div>
                              ))}
                            <div className='flex justify-end items-center gap-x-15'>
                              {isEditor &&
                                (isEditing || isAdding ? (
                                  <Fragment>
                                    <button
                                      className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                      onClick={
                                        isAdding
                                          ? toggleIsAdding
                                          : handleCancelEdit
                                      }>
                                      <span className='text-15 font-semibold'>
                                        Cancel
                                      </span>
                                      <Icon
                                        icon='lucide:x'
                                        className='w-20 h-20'
                                      />
                                    </button>
                                    <button
                                      disabled={isSubmitting}
                                      className={`inline-flex items-center gap-x-4 ${
                                        isSubmitting
                                          ? "text-placeholder-redx"
                                          : "text-blue-redx"
                                      } cursor-pointer`}
                                      onClick={handleSave}>
                                      <span className='text-15 font-semibold'>
                                        Save
                                      </span>
                                      <Icon
                                        icon='lucide:save'
                                        className='w-20 h-20'
                                      />
                                    </button>
                                  </Fragment>
                                ) : (
                                  <EditButton toggleEditing={toggleIsEditing} />
                                ))}
                            </div>
                          </div>
                          <div className='flex w-full pb-60'>
                            {!isLoading && sources && (
                              <SourcesList sources={sources} />
                            )}

                            {isLoading && (
                              <div className='inline-flex gap-10'>
                                {Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                    key={index}
                                    className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </TabPanels>
                      </TabGroup>
                    </div>
                  </TabPanel>
                ))
              ) : (
                <TabPanel>
                  <div className='pt-5'>
                    <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                    <div className='mt-20' />

                    <TabGroup
                      className='flex flex-row w-full gap-24'
                      selectedIndex={activeCategoryTab}
                      onChange={setActiveCategoryTab}>
                      <TabList className='w-2/3 border-r-1 border-r-stroke-redx flex flex-col justify-start items-start'>
                        {Array.from({ length: 3 }).map((_, index) => {
                          return (
                            <Tab
                              key={index}
                              className='w-full text-start py-10 text-16 leading-20 text-black-redx font-normal data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold'
                              ref={(el: HTMLElement | null) => {
                                if (el) {
                                  (
                                    tabsCategoryTypeRef.current as HTMLElement[]
                                  )[index] = el;
                                }
                              }}>
                              <p className='font-normal'>
                                <div className='w-2/3 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                              </p>
                              <p className='text-grey-redx text-12 leading-14 mt-10'>
                                <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                              </p>
                            </Tab>
                          );
                        })}
                      </TabList>
                      <TabPanels className='flex flex-col grow w-4/5'>
                        <div className='-mt-24'>
                          <SliderContainer
                            isEditing={false}
                            isAdding={false}
                            length={3}
                            selectedIndexes={[]}
                            goToIndex={goToIndex}
                            onActiveIndex={(index) => setActiveIndex(index)}>
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div className='my-24'>
                                <Card
                                  project={project}
                                  index={-1}
                                  id={-1}
                                  selectedFramework=''
                                  key={index}
                                  isFetching={true}
                                  item={{
                                    reason: "",
                                    media_types: "",
                                    idea_example: "",
                                    success_measurement: "",
                                    digital_recommendation: "",
                                  }}
                                  isEditor={false}
                                />
                              </div>
                            ))}
                          </SliderContainer>
                        </div>

                        <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                                onClick={toggleIsAdding}>
                                Add {handleTitleFunnel(selectedFunnel)}{" "}
                                Touchpoint Manually
                                <Icon
                                  icon='lucide:plus'
                                  className='w-20 h-20'
                                />
                              </button>
                            ) : (
                              <div></div>
                            ))}
                          <div className='flex justify-end items-center gap-x-15'>
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <Fragment>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }>
                                    <span className='text-15 font-semibold'>
                                      Cancel
                                    </span>
                                    <Icon
                                      icon='lucide:x'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                                    onClick={() => {}}>
                                    <span className='text-15 font-semibold'>
                                      Save
                                    </span>
                                    <Icon
                                      icon='lucide:save'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                </Fragment>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className='flex w-full pb-60'>
                          {!isLoading && sources && (
                            <SourcesList sources={sources} />
                          )}

                          {isLoading && (
                            <div className='inline-flex gap-10'>
                              {Array.from({ length: 3 }).map((_, index) => (
                                <div
                                  key={index}
                                  className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </TabPanel>
              )}
            </TabPanels>
          </TabGroup>
        </div>
      )}

      <SelectingFunnel
        onRegenerate={handleChangeFramework}
        defaultSelected={selectedFramework}
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        project={project}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateNextProcess}
        onClose={() => setShowRegenerateModal(false)}
      />
    </Fragment>
  );
};

export default Index;
