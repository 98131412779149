import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Icon } from "@iconify/react";

import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import { ProjectContext } from "@/Context/ProjectContext";

import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { SubmissionFormPayload } from "@/Types/form";
import RegenerateModal from "@/Components/Modals/RegenerateModal";

interface Competitor {
  name: string;
  website: string;
}

interface CompetitorInformationProps {
  data: {
    competitors: Competitor[];
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const CompetitorInformation = forwardRef<
  HTMLDivElement,
  CompetitorInformationProps
>(
  (
    {
      data,
      setData,
      errors,
      setError,
      isActive,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
      isEditingCount, 
      setIsEditingCount
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [competitorCount, setCompetitorCount] = useState(
      data.competitors.length == 0 ? 1 : data.competitors.length,
    );
    const [maxCompetitorCount, setMaxCompetitorCount] = useState(4);
    const perseError = errors.websites?.message
      ? JSON.parse(errors.websites?.message)
      : {};

    const changeCompetitorName = (
      e: React.ChangeEvent<HTMLInputElement>,
      changeIndex: number,
    ) => {
      const value = e.target.value;
      const updatedCompetitors = [...data.competitors];
      if (updatedCompetitors[changeIndex]) {
        updatedCompetitors[changeIndex].name = value;
      } else {
        updatedCompetitors[changeIndex] = { name: value, website: "" };
      }
      setData("competitors", updatedCompetitors);
      setError("competitors", { message: "" });
    };

    const changeCompetitorWebsite = (
      e: React.ChangeEvent<HTMLInputElement>,
      changeIndex: number,
    ) => {
      const value = e.target.value;
      const updatedCompetitors = [...data.competitors];
      if (updatedCompetitors[changeIndex]) {
        updatedCompetitors[changeIndex].website = value;
      } else {
        updatedCompetitors[changeIndex] = { name: "", website: value };
      }
      setData("competitors", updatedCompetitors);
      setError("competitors", { message: "" });
    };

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(3);

      if (!isNotError) {
        toggleIsEditing(true);
        return;
      }
      toggleIsEditing(false);
    };

    const toggleIsEditing = (isEdit: boolean) => {
      setIsEditing(isEdit);
      if (setIsEditingCount) {
        let newCount =  isEdit ? isEditingCount + 1 : isEditingCount - 1;
        setIsEditingCount(newCount);
      }
    };

    const handleAddCompetitor = () => {
      if (competitorCount + 1 <= maxCompetitorCount) {
        setCompetitorCount(competitorCount + 1);
      }
    };

    useEffect(() => {
      setCompetitorCount(
        data.competitors.length == 0 ? 1 : data.competitors.length,
      );
    }, [data.competitors]);

    return (
      <div
        ref={ref}
        className={`flex flex-col w-full mb-24 ${
          isActive ? "" : "opacity-30 cursor-not-allowed"
        }`}>
        <div className='flex justify-between items-center w-full'>
          <h1 className='font-bold text-24 leading-32 mb-15'>
            Competitor Information
          </h1>
          {!isFetching &&
            state.submission.is_competitor_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <div
                className=' inline-flex gap-8 justify-center items-center cursor-pointer'
                onClick={() => toggleIsEditing(true)}>
                <span className='text-14 font-semibold text-blue-redx'>
                  Edit
                </span>
                <Icon
                  icon='lucide:pencil-line'
                  className='text-blue-redx w-16 h-16'
                />
              </div>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className='inline-flex gap-8'>
              <button
                className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                onClick={() => {
                  fetchData();
                  toggleIsEditing(false);
                }}>
                <span className='text-15 font-semibold'>Cancel</span>
                <Icon
                  icon='lucide:x'
                  className='size-16'
                />
              </button>

              <button
                className='inline-flex items-center gap-x-4 cursor-pointer'
                onClick={() => setIsModalOpen(true)}>
                <p className='text-14 font-semibold text-blue-redx'>Save</p>
                <Icon
                  icon='lucide:save'
                  className='text-blue-redx size-16'
                />
              </button>
            </div>
          )}
        </div>
        <div className='grid grid-cols-2 w-full gap-24 '>
          {isFetching
            ? Array.from({ length: 3 }).map((_, index) => (
                <Fragment key={index}>
                  <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
                  <div
                    className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8'
                    key={index}
                  />
                </Fragment>
              ))
            : [...Array(competitorCount)].map((_, index) => (
                <Fragment key={index}>
                  <div className=''>
                    <InputLabel
                      htmlFor={`competitor_name${index + 1}`}
                      value={`Competitor Name ${index + 1} (Optional)`}
                      className='!text-black-redx'
                    />
                    {(!state.submission.is_competitor_information_complete ||
                      isEditing) &&
                    isEditor ? (
                      <TextInput
                        id={`competitor_name${index + 1}`}
                        type='text'
                        value={data.competitors[index]?.name || ""}
                        containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                        className='mt-1 block w-full !text-14'
                        placeholder='Input your competitor name here'
                        disabled={!isActive}
                        isFocused={true}
                        onChange={(e) => changeCompetitorName(e, index)}
                      />
                    ) : (
                      <p className='!text-14 px-0 py-6'>
                        {data.competitors[index]?.name || "-"}
                      </p>
                    )}
                  </div>
                  <div className=''>
                    <InputLabel
                      htmlFor={`competitor_website${index + 1}`}
                      value={`Competitor Website ${index + 1} (Optional)`}
                      className='!text-black-redx'
                    />
                    {(!state.submission.is_competitor_information_complete ||
                      isEditing) &&
                    isEditor ? (
                      <TextInput
                        id={`competitor_website${index + 1}`}
                        type='text'
                        value={data.competitors[index]?.website || ""}
                        error={perseError?.[index] ?? ""}
                        containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                        className='mt-1 block w-full !text-14'
                        placeholder='Input your competitor website here'
                        disabled={!isActive}
                        isFocused={true}
                        onChange={(e) => changeCompetitorWebsite(e, index)}
                      />
                    ) : (
                      <p className='!text-14 px-0 py-6'>
                        {data.competitors[index]?.website || "-"}
                      </p>
                    )}
                    {perseError?.[index] && (
                      <InputError
                        message={perseError?.[index]}
                        className='mt-2'
                      />
                    )}
                  </div>
                </Fragment>
              ))}
        </div>
        {(!state.submission.is_competitor_information_complete || isEditing) &&
          isActive &&
          !isFetching && (
            <div className='flex w-full justify-end items-center mb-24 gap-x-15'>
              {competitorCount < maxCompetitorCount ? (
                <Fragment>
                  <button
                    className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer mt-10'
                    onClick={() => handleAddCompetitor()}>
                    <p className='text-14 font-semibold text-blue-redx'>
                      Add Competitor
                    </p>
                    <Icon
                      icon='material-symbols:add'
                      className='text-blue-redx w-16 h-16'
                    />
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <div className='inline-flex items-center gap-x-4 text-grey-redx cursor-not-allowed mt-10'>
                    <p className='text-14 font-semibold text-grey'>
                      Add Competitor
                    </p>
                    <Icon
                      icon='material-symbols:add'
                      className='text-grey-redx w-16 h-16'
                    />
                  </div>
                </Fragment>
              )}
            </div>
          )}
        <RegenerateModal
          isRegenerating={false}
          isOpen={isModalOpen}
          onClose={() => {
            fetchData();
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          handleSubmit={submitForm}
        />
      </div>
    );
  },
);

export default CompetitorInformation;
