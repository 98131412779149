// Import your PDF component

import { PDFDownloadLink } from "@react-pdf/renderer";
import MyPDF from "../Print/MyPdf";
import { ProjectProps } from "@/Types/projects";
import { OnePageStrategy } from "@/Types/one-page/one-page-strategy";
import { Icon } from "@iconify/react/dist/iconify.js";

interface PDFProps {
  isFetching: boolean;
  project: ProjectProps;
  parsedData: OnePageStrategy;
}

const DownloadPDFButton = ({ isFetching, project, parsedData }: PDFProps) => {
  return (
    <div className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'>
      {isFetching ? (
        <div className='w-88 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
      ) : (
        <>
          <PDFDownloadLink
            document={
              <MyPDF
                project={project}
                parsedData={parsedData}
              />
            }
            fileName='one-page.pdf'>
            {({ loading }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
          <Icon
            icon='lucide:download'
            className='w-20 h-20'
          />
        </>
      )}
    </div>
  );
};

export default DownloadPDFButton;
