import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  IdeaAlignmentSchema,
  Schema,
} from "@/Types/creative-idea-alignment/schema";
import CardInput from "@/Components/Projects/SliderCard/CardInput";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import {
  IdeaAlignmentContent,
  IdeaAlignmentKeys,
  IdeaAlignmentList,
} from "@/Types/creative-idea-alignment/index";
import { Icon } from "@iconify/react";
import { CardHandles } from "../Index";
import { ProjectProps } from "@/Types/projects";
import { useUpdateSelectedIdeaAlignment } from "@/Hooks/react-query/idea-alignment/useUpdateSelected";
import MarkdownView from "@/Components/MardownView";

const Card = forwardRef<
  CardHandles,
  {
    project: ProjectProps;
    id: number;
    totalIdeaAlignment: number;
    ideaAlignment: IdeaAlignmentContent;
    selectedIdea?: IdeaAlignmentList | null;
    index: number;
    activeIndex: number;
    isEditing: boolean;
    isEdited: boolean;
    isEditor: boolean;
    isFetching?: boolean;
    isSelected: boolean;
    onSubmitSuccess: (data: Schema, id: number, index: number) => void;
    onSelectSuccess: () => void;
  }
>(
  (
    {
      project,
      isFetching = false,
      id,
      totalIdeaAlignment,
      ideaAlignment,
      index,
      isEditing,
      isEdited,
      isSelected,
      isEditor,
      onSelectSuccess,
      onSubmitSuccess,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaAlignment();

    const defaultValues: Schema = useMemo(
      () => ({
        idea_title: ideaAlignment?.idea_title ?? "",
        idea_description: ideaAlignment?.idea_description ?? "",
        reason: ideaAlignment?.reason ?? "",
      }),
      [ideaAlignment],
    );

    const onSubmit = (data: Schema) => {
      onSubmitSuccess(data, id, index);
    };

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(IdeaAlignmentSchema),
      defaultValues,
      mode: "all",
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit((formData) => onSubmit(formData))(),
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      reset(defaultValues);
    }, [isEditing]);

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSelectSuccess();
          },
        },
      );
    };

    return (
      <>
        <form className='flex flex-col w-full transition duration-500'>
          {!isFetching && (
            <div className='py-15 px-35 text-center inline-flex gap-8 justify-center items-center'>
              <h1 className='text-1620 font-bold'>
                Idea Alignment {index + 1} of {totalIdeaAlignment}
              </h1>
              {(isEdited || id === -1) && (
                <div className='bg-soft-grey-redx px-8 py-4 text-grey-redx text-12 leading-14 rounded-8'>
                  Edited
                </div>
              )}
            </div>
          )}

          {isFetching && (
            <h1 className='w-3/12 h-20 animate-pulse bg-soft-purple-redx rounded-full mx-auto mb-14' />
          )}
          <div
            className={`p-24 grid grid-cols-1 gap-24 transition duration-500 text-black-redx ${
              isEditing && isSelected ? "!bg-transparent" : ""
            } ${
              isSelected
                ? "border-t-2 border-blue-redx bg-softer-purple-redx"
                : "border-t-1 border-stroke-redx"
            }`}>
            {Object.keys(IdeaAlignmentSchema.shape).map((field, idx) => (
              <Controller
                control={control}
                name={field as keyof Schema}
                key={idx}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const title =
                    field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/_/g, " ");

                  return (
                    <CardInput
                      isFetching={isFetching}
                      isEditing={isEditing}
                      value={value}
                      defaultValue={ideaAlignment?.[field as IdeaAlignmentKeys]}
                      error={error?.message}
                      placeholder={`Input the ${field.replace(/_/g, " ")} here`}
                      title={title}
                      onChange={onChange}
                    />
                  );
                }}
              />
            ))}
          </div>
          {ideaAlignment?.reason && !isEdited && (
            <div className='w-full inline-flex gap-7 p-10 items-center border-b-1 border-t-1 border-soft-purple-redx mb-20 px-30'>
              <div className='w-20 mx-20'>
                <Icon
                  className='size-20 text-black-redx'
                  icon='lucide:message-circle-question'
                />
              </div>
              <div className='w-auto'>
                <h1 className='font-semibold text-12 leading-24'>
                  How Ainstein aligned this Idea?
                </h1>

                <div className='text-grey-redx text-12 leading-18 font-normal'>
                  <MarkdownView content={ideaAlignment.reason} />
                </div>
              </div>
            </div>
          )}
        </form>
        <div className='w-full justify-center flex items-center mb-35 mt-15'>
          {id !== -1 && isEditor && (
            <SelectButton
              index={index}
              isLoading={isUpdatingSelected}
              isEditing={isEditing}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
