import { fetcher } from "@/Services/axios";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "@/Components/TopMenu/Breadcrumb";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveProjectData,
  saveRouteNext,
  saveRoutePrev,
  saveSubmissionData,
  setMarketResearchLoading,
  setProductResearchLoading,
} from "@/Context/actions/projectActions";
import { useGenerate } from "@/Context/hooks/useGenerate";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { SubmissionFormPayload } from "@/Types/form";
import { validateForm } from "./Helpers/validation";
import AudienceArchetype from "./Partials/AudienceArchetype";
import CompetitorInformation from "./Partials/CompetitorInformation";
import ErrorToast from "./Partials/ErrorToast";
import MarketInformation from "./Partials/MarketInformation";
import ProductInformation from "./Partials/ProductInformation";
import ProjectObjective from "./Partials/ProjectObjective";
import useUser from "@/Hooks/react-query/useUser";
import { SubmissionResponse } from "@/Types/submission";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";

const Index = ({ data }: { data: SubmissionResponse }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditingCount, setIsEditingCount] = useState(0);
  const navigate = useNavigate();
  const { data: user } = useUser();
  const [isFetching, setIsFetching] = useState(true);
  const [fetchingSection, setFetchingSection] = useState("");
  const { roles } = useRolesBadge(data.project, user);
  const isEditor = roles.includes("Strategist") || roles.includes("Owner");
  const {
    handleGenerateCompetitorAnalysis,
    handleGenerateProductResearchProductDescription,
    handleGenerateProductResearchMainFeatures,
    handleGenerateProductResearchMarcommHighlights,
    handleGenerateMarketDemand,
    handleGenerateMarketTrends,
    handleGenerateTargetAudienceAudienceInsight,
    handleGenerateTargetAudienceBehavioralTrend,
    handleGenerateTargetAudienceMediaConsumptionPatterns,
    handleGenerateTargetAudiencePerceptionAnalysis,
  } = useGenerate(dispatch);
  const {
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    isCompetitorAnalysisComplete,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPending: isPendingHistory,
    isFetched: isFetchedHistory,
  } = useHistoryStatus(data.project);

  const {
    setValue,
    getValues,
    formState: { errors },
    reset,
    setError,
    watch,
  } = useForm<SubmissionFormPayload>({
    defaultValues: {
      product_name: "",
      brand_name: "",
      product_links: "",
      product_category: "",
      product_subcategory: "",
      age_market_start: "",
      age_market_end: "",
      gender: "",
      competitors: [],
      objective: "",
      second_objective: "",
      kpi: "",
    },
  });

  useEffect(() => {
    // Set the loading state to true before setting values
    setIsFetching(true);

    // Simulate setting values (e.g., from an API call)
    setValue("product_name", state.submission.product_name ?? "");
    setValue("brand_name", state.submission.brand_name ?? "");
    setValue("product_links", state.submission.product_website ?? "");
    setValue(
      "product_category",
      state.submission.category?.parent
        ? state.submission.category.parent.id.toString()
        : "",
    );
    setValue(
      "product_subcategory",
      state.submission.category ? state.submission.category.id.toString() : "",
    );
    setValue(
      "age_market_start",
      state.submission.age_market_start
        ? state.submission.age_market_start.toString()
        : "",
    );
    setValue(
      "age_market_end",
      state.submission.age_market_end
        ? state.submission.age_market_end.toString()
        : "",
    );
    setValue("gender", state.submission.gender ?? "");
    setValue("competitors", state.submission.competitors ?? []);
    setValue("objective", state.submission.objective ?? "");
    setValue("second_objective", state.submission.second_objective ?? "");
    setValue("kpi", state.submission.kpi ?? "");

    setTimeout(() => {
      setIsFetching(false);
    }, 1000);
  }, []);

  watch();

  const formSections = [
    "Product Information",
    "Market Information",
    "Target Audience",
    "Competitor Information",
    "Campaign Objective",
  ];
  const [activeSection, setActiveSection] = useState(0);
  const [categoriesList] = useState<{ title: string; value: number }[]>(
    data.categories.map((item: any) => {
      return {
        title: item.name,
        value: item.id,
      };
    }),
  );
  const [subcategoriesList, setSubcategoriesList] = useState<
    { title: string; value: number }[]
  >([]);
  const [genderList] = useState([
    {
      title: "Male",
      value: "Male",
      isChecked: false,
    },
    {
      title: "Female",
      value: "Female",
      isChecked: false,
    },
  ]);

  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const sectionRefs = formSections.map(() => useRef<HTMLDivElement>(null));

  const filterSubCategoriesList = (category: string) => {
    if (category === "") {
      return [];
    }
    const subcategoriesList = data.subcategories
      .filter((item: any) => item.parent_id == category)
      .map((item: any) => {
        return {
          title: item.name,
          value: item.id,
        };
      });
    return subcategoriesList;
  };

  const handleRouteNext = () => {
    const isIncomplete = [
      !state.submission.is_product_information_complete,
      !state.submission.is_market_information_complete,
      !state.submission.is_audience_information_complete,
      !state.submission.is_competitor_information_complete,
      !state.submission.is_objective_information_complete,
    ][activeSection];

    const onClick = isIncomplete
      ? async () => await handleNextSection()
      : () => {
          sectionRefs[activeSection + 1].current?.scrollIntoView({
            behavior: "smooth",
          });
          setActiveSection(activeSection + 1);
        };

    const label =
      activeSection === formSections.length - 1
        ? "Next to Product Research"
        : "Next";

    const onClickRoute =
      activeSection === formSections.length - 1 &&
      state.submission.is_objective_information_complete
        ? () => navigate(`/${data.project.slug}/product-research`)
        : onClick;

    let isDisabled = false;
    if (isEditingCount > 0) {
      isDisabled = true;
    }

    return dispatch(
      saveRouteNext({
        label,
        isActive: true,
        isDisabled: isDisabled,
        onClick: onClickRoute,
      }),
    );
  };

  const handleRoutePrev = () => {
    if (activeSection !== 0) {
      return dispatch(
        saveRoutePrev({
          label: "Back",
          isActive: true,
          onClick: () => {
            sectionRefs[activeSection - 1].current?.scrollIntoView({
              behavior: "smooth",
            });
            setActiveSection(activeSection - 1);
          },
        }),
      );
    }
    return dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: false,
        onClick: () => {},
      }),
    );
  };

  const changeInformation = () => {
    if (activeSection < formSections.length - 1) {
      return dispatch(
        saveInformation(
          `Click next to continue to ${formSections[
            activeSection + 1
          ].toLowerCase()}`,
        ),
      );
    }
    return dispatch(saveInformation(""));
  };

  const checkActiveSection = () => {
    let activateSection = 0;
    if (state.submission.is_product_information_complete) {
      activateSection = 1;
    }
    if (state.submission.is_market_information_complete) {
      activateSection = 2;
    }
    if (state.submission.is_audience_information_complete) {
      activateSection = 3;
    }
    if (state.submission.is_competitor_information_complete) {
      activateSection = 4;
    }
    if (state.submission.is_objective_information_complete) {
      activateSection = 4;
    }
    return setActiveSection(activateSection);
  };

  useEffect(() => {
    dispatch(
      setProductResearchLoading({
        status: false,
        progress: 0,
      }),
    );
    dispatch(
      setMarketResearchLoading({
        status: false,
        progress: 0,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setSubcategoriesList(
      filterSubCategoriesList(getValues("product_category")),
    );
  }, [getValues("product_category")]);

  useEffect(() => {
    handleRouteNext();
    handleRoutePrev();
    changeInformation();
  }, [activeSection, errors, dispatch, isEditingCount]);

  useEffect(() => {
    if (sectionContainerRef) sectionContainerRef.current?.scrollTo(0, 0);
  }, [activeSection]);

  useEffect(() => {
    fetchData();
    checkActiveSection();
  }, [data.project, dispatch]);

  useEffect(() => {
    checkActiveSection();
  }, [state.submission]);

  const fetchData = async () => {
    dispatch(saveProjectData(data.project));
    const submissionStatus = await fetcher
      .get(`/${data.project.slug}/submission/status`)
      .then((response) => response.data);

    const updatedSubmissionData = {
      ...data.project.submissions[0],
      ...submissionStatus,
      competitors: submissionStatus.submission.competitors,
    };
    dispatch(saveSubmissionData(updatedSubmissionData));

    reset({
      product_name: updatedSubmissionData.product_name ?? "",
      brand_name: updatedSubmissionData.brand_name ?? "",
      product_links: updatedSubmissionData.product_website ?? "",
      product_category:
        updatedSubmissionData.category?.parent?.id.toString() ?? "",
      product_subcategory: updatedSubmissionData.category?.id.toString() ?? "",
      age_market_start:
        updatedSubmissionData.age_market_start?.toString() ?? "",
      age_market_end: updatedSubmissionData.age_market_end?.toString() ?? "",
      gender: updatedSubmissionData.gender ?? "",
      competitors: updatedSubmissionData.competitors ?? [],
      objective: updatedSubmissionData.objective ?? "",
      second_objective: updatedSubmissionData.second_objective ?? "",
      kpi: updatedSubmissionData.kpi ?? "",
    });
  };

  const handleNextSection = async () => {
    try {
      const isNotError = await handleSubmitSection(activeSection);
      if (!isNotError) {
        return;
      }

      if (activeSection === formSections.length - 1) {
        return navigate(`/${data.project.slug}/product-research`);
      }

      setActiveSection(activeSection + 1);
      sectionRefs[activeSection + 1].current?.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {
      toast.custom((t) => (
        <ErrorToast
          handleClick={() => {
            toast.remove();
            handleSubmitSection(activeSection);
          }}
          t={t}
        />
      ));
    }
  };

  const handleSubmitSection = async (sectionIndex: number) => {
    const isError = await validateForm(sectionIndex, setError, getValues());
    if (isError) {
      return false;
    }
    const routeMap: { [key: number]: string } = {
      0: "/submission/product-information",
      1: "/submission/market-information",
      2: "/submission/audience-archetype",
      3: "/submission/competitors",
      4: "/submission/project-objective",
    };
    const routeKey = routeMap[sectionIndex];
    submissionSave(routeKey, sectionIndex);
    return true;
  };

  const submissionSave = async (routeKey: string, sectionIndex: number) => {
    try {
      const submissionData = await fetcher
        .put(`/${data.project.slug}${routeKey}`, getValues())
        .then((response) => response.data);
      const submissionStatus = await fetcher
        .get(`/${data.project.slug}/submission/status`)
        .then((response) => response.data);

      const updatedSubmissionData = {
        ...submissionData,
        ...submissionStatus,
      };
      dispatch(saveSubmissionData(updatedSubmissionData));
      if (sectionIndex === 0) {
        handleGenerateProductResearchProductDescription()(data.project.slug);
        handleGenerateProductResearchMainFeatures()(data.project.slug);
        handleGenerateProductResearchMarcommHighlights()(data.project.slug);
      }
      if (sectionIndex === 1) {
        handleGenerateMarketDemand()(data.project.slug);
        handleGenerateMarketTrends()(data.project.slug);
      }
      if (sectionIndex === 2) {
        handleGenerateTargetAudienceAudienceInsight()(data.project.slug);
        handleGenerateTargetAudienceBehavioralTrend()(data.project.slug);
        handleGenerateTargetAudienceMediaConsumptionPatterns()(
          data.project.slug,
        ),
          handleGenerateTargetAudiencePerceptionAnalysis()(data.project.slug);
      }
      if (sectionIndex === 3) {
        handleGenerateCompetitorAnalysis()(data.project.slug);
      }
    } catch (error: any) {
      setActiveSection(activeSection);
      toast.custom((t) => {
        return (
          <ErrorToast
            message={error?.response?.data?.message}
            handleClick={() => {
              toast.remove();
              handleSubmitSection(activeSection);
            }}
            t={t}
          />
        );
      });
      return false;
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    sectionRefs[index].current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!isPendingHistory) {
      if (isFetchedHistory) {
        !isTargetAudienceAudienceInsight.isQuerying &&
          isTargetAudienceAudienceInsight.isRegenerated &&
          handleGenerateTargetAudienceAudienceInsight()(data.project.slug);

        !isTargetAudienceMediaConsumptionPattern.isQuerying &&
          isTargetAudienceMediaConsumptionPattern.isRegenerated &&
          handleGenerateTargetAudienceMediaConsumptionPatterns()(
            data.project.slug,
          );

        !isTargetAudienceBehavioralTrend.isQuerying &&
          isTargetAudienceBehavioralTrend.isRegenerated &&
          handleGenerateTargetAudienceBehavioralTrend()(data.project.slug);

        !isTargetAudiencePerceptionAnalysis.isQuerying &&
          isTargetAudiencePerceptionAnalysis.isRegenerated &&
          handleGenerateTargetAudiencePerceptionAnalysis()(data.project.slug);

        !isCompetitorAnalysisComplete.isQuerying &&
          isCompetitorAnalysisComplete.isRegenerated &&
          handleGenerateCompetitorAnalysis()(data.project.slug);

        !isMarketResearchDemand.isQuerying &&
          isMarketResearchDemand.isRegenerated &&
          handleGenerateMarketDemand()(data.project.slug);

        !isMarketResearchTrend.isQuerying &&
          isMarketResearchTrend.isRegenerated &&
          handleGenerateMarketTrends()(data.project.slug);
      }
    }
  }, [
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    isCompetitorAnalysisComplete,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPendingHistory,
    isFetchedHistory,
  ]);
  return (
    <>
      <title title='Submission' />

      <div className='flex flex-col w-full h-full py-24'>
        <Breadcrumb
          list={formSections}
          index={activeSection}
          onBreadCrumbClick={handleBreadcrumbClick}
          sectionRefs={sectionRefs}
          sectionContainerRef={sectionContainerRef}
        />
        <div
          className='relative flex-1 overflow-y-auto overflow-custom pb-80 pr-20 max-h-538'
          ref={sectionContainerRef}>
          <ProductInformation
            ref={sectionRefs[0]}
            errors={errors}
            setError={setError}
            isActive={!(activeSection < 0)}
            onEditSection={handleSubmitSection}
            fetchData={async () => {
              setFetchingSection("product_information");
              await fetchData();
              setFetchingSection("");
            }}
            isEditor={isEditor}
            data={getValues()}
            setData={setValue}
            isFetching={isFetching || fetchingSection === "product_information"}
            isEditingCount={isEditingCount}
            setIsEditingCount={setIsEditingCount}
          />
          <MarketInformation
            ref={sectionRefs[1]}
            errors={errors}
            setError={setError}
            isActive={!(activeSection < 1)}
            categories={categoriesList}
            subcategories={subcategoriesList}
            onEditSection={handleSubmitSection}
            fetchData={async () => {
              setFetchingSection("market_information");
              await fetchData();
              setFetchingSection("");
            }}
            isEditor={isEditor}
            data={getValues()}
            setData={setValue}
            isFetching={isFetching || fetchingSection === "market_information"}
            isEditingCount={isEditingCount}
            setIsEditingCount={setIsEditingCount}
          />
          <AudienceArchetype
            ref={sectionRefs[2]}
            errors={errors}
            setError={setError}
            isActive={!(activeSection < 2)}
            genders={genderList}
            onEditSection={handleSubmitSection}
            fetchData={async () => {
              setFetchingSection("audience_archetype");
              await fetchData();
              setFetchingSection("");
            }}
            isEditor={isEditor}
            data={getValues()}
            setData={setValue}
            isFetching={isFetching || fetchingSection === "audience_archetype"}
            isEditingCount={isEditingCount}
            setIsEditingCount={setIsEditingCount}
          />
          <CompetitorInformation
            ref={sectionRefs[3]}
            errors={errors}
            setError={setError}
            isActive={!(activeSection < 3)}
            onEditSection={handleSubmitSection}
            fetchData={async () => {
              setFetchingSection("competitor");
              await fetchData();
              setFetchingSection("");
            }}
            isEditor={isEditor}
            data={getValues()}
            setData={setValue}
            isFetching={isFetching || fetchingSection === "competitor"}
            isEditingCount={isEditingCount}
            setIsEditingCount={setIsEditingCount}
          />
          <ProjectObjective
            ref={sectionRefs[4]}
            errors={errors}
            setError={setError}
            isActive={!(activeSection < 4)}
            onEditSection={handleSubmitSection}
            fetchData={async () => {
              setFetchingSection("objective");
              await fetchData();
              setFetchingSection("");
            }}
            isEditor={isEditor}
            data={getValues()}
            setData={setValue}
            isFetching={isFetching || fetchingSection === "objective"}
            isEditingCount={isEditingCount}
            setIsEditingCount={setIsEditingCount}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
