import GenerateLoading from "@/Components/GenerateLoading";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SourcesList from "@/Components/Projects/SourcesList";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { ProjectProps, SourcesType } from "@/Types/projects";
import {
  AudienceInsightProps,
  TargetAudienceItem,
} from "@/Types/target_audience";
import { isYourRolesCanEdit } from "@/Utils/helper";
import { Icon } from "@iconify/react/dist/iconify.js";
import React, { Fragment, useContext, useEffect, useState } from "react";
import MarkdownEditor from "@/Components/MarkdownEditor";
import { ProjectContext } from "@/Context/ProjectContext";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { jsonToMarkdown, markdownToJson } from "../Utils/utils";
import TargetAudienceList from "./TargetAudienceList";
import { progressPortion } from "@/Utils/dispatcher";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  AudienceInsights,
  AudienceInsightsSchema,
} from "@/Types/target_audience/schema-audience-insights";
import useUpdateTargetAudience from "@/Hooks/react-query/target-audience/useUpdateTargetAudience";
import useUser from "@/Hooks/react-query/useUser";
import { SectionList } from "@/Types/tabs";

interface Props {
  project: ProjectProps;
  section: SectionList;
  isEditing: boolean;
  isLoading: boolean;
  isGenerateAudienceInsight: boolean;
  toggleIsEditing: () => void;
}

const CardAudienceInsights: React.FC<Props> = ({
  project,
  section,
  isEditing,
  isLoading,
  isGenerateAudienceInsight,
  toggleIsEditing,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const { data: user } = useUser();

  const maxGenerateLimit = project.max_generated_data;

  const { roles } = useRolesBadge(project, user);
  const [parsedData, setParsedData] = useState<AudienceInsightProps | null>({
    passion_points: [],
    pain_points: [],
    expectations: [],
    motivations: [],
  });
  const [passionPoints, setPassionPoints] = useState<TargetAudienceItem[]>([]);
  const [motivations, setMotivations] = useState<TargetAudienceItem[]>([]);
  const [painPoints, setPainPoints] = useState<TargetAudienceItem[]>([]);
  const [needsExpectations, setNeedsExpectations] = useState<
    TargetAudienceItem[]
  >([]);

  const {
    handleGenerateTargetAudienceAudienceInsight,
    handleSetTargetAudienceAudienceInsights,
  } = useGenerate(dispatch);
  const { mutate: updateTargetAudience } = useUpdateTargetAudience();

  const stateProps = state.targetAudience.audience_insights;
  const progress = stateProps.progress;
  const data = stateProps.data;
  const content = data.content;
  const sources = data.sources;
  const total_regenerate: number = data.total_regenerate
    ? data.total_regenerate
    : 0;
  const { isTargetAudienceAudienceInsight: isTargetAudience } =
    useHistoryStatus(project);
  const [isGenerate, setIsGenerate] = useState(false);
  const isEditor = roles.includes("Owner") || roles.includes("Strategist");

  const { reset, setValue, getValues } = useForm<AudienceInsightsSchema>({
    resolver: zodResolver(AudienceInsights),
    defaultValues: {
      passion_points: "",
      motivations: "",
      pain_points: "",
      needs_expectations: "",
    },
    mode: "all",
  });

  const handleSetParsedData = () => {
    const stringContent = content;
    if (stringContent) {
      try {
        const parsedContent = JSON.parse(stringContent) as AudienceInsightProps;

        setParsedData(parsedContent);
        setPassionPoints(parsedContent.passion_points);
        setMotivations(parsedContent.motivations);
        setPainPoints(parsedContent.pain_points);
        setNeedsExpectations(parsedContent.expectations);

        reset({
          passion_points: jsonToMarkdown(parsedContent.passion_points),
          motivations: jsonToMarkdown(parsedContent.motivations),
          pain_points: jsonToMarkdown(parsedContent.pain_points),
          needs_expectations: jsonToMarkdown(parsedContent.expectations),
        });
      } catch (error) {
        console.error(error, "error audience insights");
        console.log(
          "error while parsing =>",
          stringContent,
          "audience insights",
        );
      }
    }
  };

  const handleSaveData = () => {
    toggleIsEditing();
    const formattedContent: AudienceInsightProps = {
      passion_points: markdownToJson(getValues("passion_points")),
      motivations: markdownToJson(getValues("motivations")),
      pain_points: markdownToJson(getValues("pain_points")),
      expectations: markdownToJson(getValues("needs_expectations")),
    };

    updateTargetAudience(
      {
        payload: JSON.stringify(formattedContent),
        historyId: data.history_id,
        projectSlug: project.slug,
      },
      {
        onSuccess: (data) => {
          handleSetTargetAudienceAudienceInsights()(data.data);
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    handleGenerateTargetAudienceAudienceInsight()(project.slug, prompt);
  };

  useEffect(() => {
    handleSetParsedData();
  }, [data]);

  useEffect(() => {
    if (!isTargetAudience.isQuerying && parsedData?.expectations.length) {
      setIsGenerate(false);
    }
  }, [isTargetAudience.isQuerying, parsedData]);

  useEffect(() => {
    if (isGenerateAudienceInsight) {
      setIsGenerate(true);
    }
  }, [isGenerateAudienceInsight]);

  if (isGenerate) {
    return (
      <GenerateLoading
        progress={progressPortion({
          progress: Number((progress / 2).toFixed(0)),
          isQuerying: isTargetAudience.isQuerying,
          isContentAvailable: parsedData?.expectations.length !== 0,
        })}
        project={project}
        section={section}
        showEmailNotification={isTargetAudience.isSendingEmail}
      />
    );
  }

  return (
    <Fragment>
      <div className='flex flex-col gap-24 text-black-redx'>
        {parsedData && (
          <Fragment>
            <div className='flex flex-col gap-5'>
              <div className='flex items-center justify-between'>
                <h3 className='font-bold text-16 leading-19'>Passion Points</h3>
                {isEditor && !isEditing && (
                  <RegenerateButton
                    onSubmit={(formData) =>
                      handleRegenerateButton(formData.prompt)
                    }
                    limit={total_regenerate}
                    maxLimit={maxGenerateLimit}
                  />
                )}
              </div>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.passion_points)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue("passion_points", jsonToMarkdown(json));
                    setPassionPoints(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx' />
                  ) : (
                    <TargetAudienceList items={passionPoints} />
                  )}
                </>
              )}
            </div>

            <div className='flex flex-col gap-5'>
              <h3 className='font-bold text-16 leading-19'>Motivations</h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.motivations)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue("motivations", jsonToMarkdown(json));
                    setMotivations(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx' />
                  ) : (
                    <TargetAudienceList items={motivations} />
                  )}
                </>
              )}
            </div>

            <div className='flex flex-col gap-5'>
              <h3 className='font-bold text-16 leading-19'>Pain Points</h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.pain_points)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue("pain_points", jsonToMarkdown(json));
                    setPainPoints(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx' />
                  ) : (
                    <TargetAudienceList items={painPoints} />
                  )}
                </>
              )}
            </div>

            <div className='flex flex-col gap-5'>
              <h3 className='font-bold text-16 leading-19'>
                Needs/Expectations
              </h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.expectations)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue("needs_expectations", jsonToMarkdown(json));
                    setNeedsExpectations(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx' />
                  ) : (
                    <TargetAudienceList items={needsExpectations} />
                  )}
                </>
              )}
            </div>
          </Fragment>
        )}
      </div>

      <div className='flex w-full justify-end items-center my-24 gap-x-15'>
        {isEditor &&
          (isEditing ? (
            <Fragment>
              <button
                className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                onClick={() => {
                  toggleIsEditing();
                  handleSetParsedData();
                }}>
                <span className='text-15 font-semibold'>Cancel</span>
                <Icon
                  icon='lucide:x'
                  className='w-20 h-20'
                />
              </button>
              <button
                className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                onClick={handleSaveData}>
                <span className='text-15 font-semibold'>Save</span>
                <Icon
                  icon='lucide:save'
                  className='w-20 h-20'
                />
              </button>
            </Fragment>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>
      <div className='flex w-full pb-60 mr-1'>
        {!isLoading && sources && (
          <SourcesList sources={sources as SourcesType[]} />
        )}
        {isLoading && (
          <div className='inline-flex gap-10'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CardAudienceInsights;
