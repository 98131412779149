import GenerateLoading from "@/Components/GenerateLoading";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SourcesList from "@/Components/Projects/SourcesList";
import SelectedCard from "@/Components/SelectedCard";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { RouteOption } from "@/Context/reducer/projectReducer";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import useOpportunities from "@/Hooks/react-query/opportunity/useGetList";
import { useUpdateOpportunities } from "@/Hooks/react-query/opportunity/useUpdate";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { fetcher } from "@/Services/axios";
import {
  OpportunityAmongCompetitionProps,
  OpportunityData,
} from "@/Types/opportunity_among_competition";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { SectionList } from "@/Types/tabs";
import { progressPortion } from "@/Utils/dispatcher";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useQueryClient } from "@tanstack/react-query";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Card from "./Partials/Card";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [editedData, setEditedData] = useState<OpportunityData[]>([]);
  const [parsedData, setParsedData] = useState<OpportunityData[]>([]);
  const [activeArchetype, setActiveArchetype] = useState<OpportunityData>();
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState<
    number[] | undefined
  >();
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const {
    isOnePageStrategy,
    isOpportunityAmongCompetitionComplete,
    isUpdateState: isPendingOpportunity,
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data, isLoading, refetch, isRefetching } = useOpportunities(
    project.slug
  );
  const { mutate } = useUpdateOpportunities();
  const [totalSelectedIdea, setTotalSelectedIdea] = useState<number>(0);
  const [totalIdea, setTotalIdea] = useState<number>(0);

  const routeOptionValue: RouteOption = {
    label: "Generate One-Page Strategy",
    isActive: true,
    isGenerate: true,
    isInactive: true,
  };
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleCancelEdit = async () => {
    toggleIsEditing();
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const {
    handleGenerateOpportunityAmongCompetition,
    handleGenerateOnePageStrategy,
  } = useGenerate(dispatch);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const handleSaveEditedData = (
    updatedData: OpportunityAmongCompetitionProps,
    index: number
  ) => {
    const { id, is_selected, ...opportunities_content } = updatedData;

    const payload = {
      opportunities_content: opportunities_content,
      id: id,
      archetype_id: activeArchetype!.id,
    };

    setUpdateLoading(true);
    mutate(
      {
        payload: payload,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          refreshData();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
        onError: (error) => {
          toast.error(error.message);
          refreshData();
        },
      }
    );
  };

  const handleRefetchSelectedData = async (isRegenerated: boolean) => {
    queryClient.resetQueries({
      queryKey: ["OnePageStrategy", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/opportunity-among-competition/check-regenerate`
    );

    if ((data && data.data.is_show_pop_up) || isRegenerated) {
      setShowRegenerateModal(data.data.is_show_pop_up || isRegenerated);
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const hasSelectedOpportunity = (parseData: OpportunityData[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedIdea = parseData
      .map((section, index) => {
        const hasSelectedIdea = section.opportunities.some(
          (opportunity) => opportunity.is_selected
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);
    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(", ");

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select idea for ${namesWithoutSelectedKeyInsight}`
        )
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateButton = async (prompt: string) => {
    dispatch(saveSubInformation(""));
    setErrorTabIndex([]);
    setParsedData([]);
    setIsGenerated(true);
    await handleGenerateOpportunityAmongCompetition()(project.slug, prompt);
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    await handleGenerateOnePageStrategy()(project.slug, null);
    setIsReGenerating(false);
    dispatch(saveSubInformation(""));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (selectedIndex < parsedData.length - 1) {
      const nextIndex = selectedIndex + 1;
      setSelectedIndex(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        "scrollIntoView" in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1;
      setSelectedIndex(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        "scrollIntoView" in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const refreshData = () => {
    isAdding && toggleIsAdding();
    isEditing && toggleIsEditing();

    setTimeout(() => {
      refetch();
    }, 700);

    setUpdateLoading(false);
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/competitor-analysis`);
        },
      })
    );
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedIndex]);

  const countSelectedData = (data: OpportunityData[]) => {
    const archetypeCounts = data.map((archetype) => {
      let totalArchetype = 0;
      let totalSelected = 0;

      if (archetype.opportunities && Array.isArray(archetype.opportunities)) {
        archetype.opportunities.forEach((opportunity) => {
          totalArchetype++;
          if (opportunity.is_selected === 1) {
            totalSelected++;
          }
        });
      }

      return {
        archetypeId: archetype.id,
        totalArchetype,
        totalSelected,
      };
    });

    const isInactive = archetypeCounts.some(
      (archetype) => archetype.totalSelected === 0
    );

    return isInactive;
  };

  useEffect(() => {
    const totalSelected = data?.total_selected_opportunities || 0;
    const totalIdea = data?.total_opportunities || 0;

    setTotalSelectedIdea(totalSelected);
    setTotalIdea(totalIdea);

    if (data?.data) {
      const {
        data: selectedArchetypesOpportunity,
        sources,
        total_regenerate,
        is_querying,
      } = data;

      if (is_querying) {
        setIsGenerated(true);
        setEditedData([]);
        setParsedData([]);
        setTotalRegenerate(0);
        setSources([]);
        return;
      }

      setEditedData(selectedArchetypesOpportunity);
      setParsedData(selectedArchetypesOpportunity);
      setTotalRegenerate(total_regenerate);
      setSources(sources);
      dispatch(saveSubInformation(""));
      setIsGenerated(false);
    }
  }, [
    data,
    isOpportunityAmongCompetitionComplete,
    differentArchetypes?.is_different_archetype.opportunities,
  ]);

  useEffect(() => {
    if (!isOpportunityAmongCompetitionComplete.status) {
      dispatch(saveInformation(`0 from 0 idea selected`));
    }

    if (isOpportunityAmongCompetitionComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isOpportunityAmongCompetitionComplete.status,
    isOpportunityAmongCompetitionComplete.isError,
  ]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.opportunities
    ) {
      if (
        !isGenerated &&
        !isPendingOpportunity &&
        !isOpportunityAmongCompetitionComplete.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveInformation(""));
        dispatch(saveSubInformation(""));
        handleGenerateOpportunityAmongCompetition()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isOpportunityAmongCompetitionComplete,
    isPendingOpportunity,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    !isOpportunityAmongCompetitionComplete.isQuerying &&
      !isPendingOpportunity &&
      refetch();

    isOpportunityAmongCompetitionComplete.isQuerying &&
      !isPendingOpportunity &&
      setIsGenerated(true);
  }, [
    isGenerated,
    isOpportunityAmongCompetitionComplete,
    isPendingOpportunity,
    differentArchetypes?.is_different_archetype.opportunities,
  ]);

  useEffect(() => {
    if (!parsedData.length) return;

    setSelectedOpportunityIndex([]);

    const activeArchetype = parsedData[selectedIndex];
    const selectedDataWithIndex = activeArchetype?.opportunities
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedOpportunityIndex(indices);

      if (errorTabIndex && errorTabIndex.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== selectedIndex) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(", ");

        dispatch(saveSubInformation(""));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select idea for ${namesWithoutSelectedKeyInsight}`
            )
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }

    setActiveArchetype(activeArchetype);
  }, [selectedIndex, parsedData]);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    dispatch(
      saveInformation(`${totalSelectedIdea} from ${totalIdea} idea selected`)
    );
  }, [totalSelectedIdea, totalIdea]);

  useEffect(() => {
    if (isEditor) {
      const isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        !isKeyInsightsComplete.status ||
        !differentArchetypes?.is_selected_content.challenges ||
        !differentArchetypes?.is_selected_content.key_insight ||
        isOpportunityAmongCompetitionComplete.isQuerying ||
        !isOpportunityAmongCompetitionComplete.status
          ? true
          : countSelectedData(parsedData);

      if (
        isOnePageStrategy.status &&
        (isOnePageStrategy.isRegenerated ||
          differentArchetypes?.is_different_archetype.one_pages)
      )
        routeOptionValue.label = "Regenerate One-Page Strategy";
      if (
        isOnePageStrategy.status &&
        !isOnePageStrategy.isRegenerated &&
        !differentArchetypes?.is_different_archetype.one_pages
      )
        routeOptionValue.label = "Next To One-Page Strategy";

      routeOptionValue.isGenerate =
        isOnePageStrategy.isRegenerated ||
        !isOnePageStrategy.status ||
        differentArchetypes?.is_different_archetype.one_pages;

      routeOptionValue.isInactive = isGenerated ? true : isInactive;

      routeOptionValue.onClick = () => {
        if (
          !isOpportunityAmongCompetitionComplete.status ||
          isGenerated ||
          isOpportunityAmongCompetitionComplete.isQuerying
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, Idea is still generating`
            )
          );
          return;
        }

        if (
          (!isKeyInsightsComplete.status &&
            !isChallengeAndTaskOfTheBrand.status) ||
          !differentArchetypes?.is_selected_content.key_insight
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please generate & choose Key Insight, Challenge & Communication Task first.`
            )
          );
          return;
        }

        if (
          !isChallengeAndTaskOfTheBrand.status ||
          !differentArchetypes?.is_selected_content.challenges
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please generate & choose Challenge & Communication Task first.`
            )
          );
          return;
        }

        if (isOnePageStrategy.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, one page is still generating`
            )
          );

          setTimeout(() => {
            dispatch(saveSubInformation(""));
          }, 3000);
          return;
        }

        if (isEditor && (isAdding || isEditing)) {
          dispatch(
            saveSubInformation(`{text-error-redx} Please do save first`)
          );

          setTimeout(() => {
            dispatch(saveSubInformation(""));
          }, 3000);
          return;
        }

        const hasError = hasSelectedOpportunity(parsedData);

        if (!hasError)
          handleRefetchSelectedData(isOnePageStrategy.isRegenerated);
      };
    } else {
      routeOptionValue.label = "Next To One-Page Strategy";
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isOnePageStrategy.status ||
        isOnePageStrategy.isQuerying ||
        differentArchetypes?.is_different_archetype.one_pages;

      routeOptionValue.onClick = () => {
        if (!isOpportunityAmongCompetitionComplete.status || isGenerated) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, Idea is still generating`
            )
          );
          return;
        }
        if (
          !isOnePageStrategy.status ||
          differentArchetypes?.is_different_archetype.one_pages
        ) {
          setShowViewInfoModal(true);
          return;
        }
        navigate(`/${project.slug}/one-page-strategy`);
      };
    }

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      })
    );
  }, [
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
    isAdding,
    isEditing,
    isOpportunityAmongCompetitionComplete,
    isOnePageStrategy,
    differentArchetypes?.is_different_archetype.one_pages,
    parsedData,
    isGenerated,
    showRegenerateModal,
    totalSelectedIdea,
    isEditor,
    isAdding,
    isEditing,
  ]);

  const sectionList: SectionList = {
    title: "Opportunity Among Competition",
    value: "",
    section: "opportunity_among_competition",
  };

  return (
    <Fragment>
      <title title="Opportunity Among Competition" />
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.opportunityAmongCompetition.progress / 2).toFixed(0)
            ),
            isQuerying: isOpportunityAmongCompetitionComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={
            isOpportunityAmongCompetitionComplete.isSendingEmail
          }
        />
      ) : (
        <div className="w-full h-full">
          <div className="flex items-center justify-between sticky top-56 z-20 bg-white py-16">
            <div className="flex flex-col w-2/3">
              <h1 className="text-25 font-bold mb-8 leading-none">
                Opportunity Among Competition
              </h1>
              <span className="text-15 font-normal text-grey-redx">
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={totalRegenerate}
                isGenerateMore={true}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          <TabGroup
            className="pb-80"
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
          >
            <div className="relative flex items-center">
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handlePrev}
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === 0}
                  >
                    <Icon icon="mingcute:left-line" className="text-25" />
                  </button>
                )}
              <TabList
                className={
                  "flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200"
                }
              >
                {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying
                  ? parsedData.map((section, index) => (
                      <Tab
                        disabled={isEditing || isAdding}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className="text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0"
                        key={index}
                      >
                        <div className="h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${index + 1}`}
                            <div className="px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx">
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              icon="mingcute:information-line"
                              className="text-25 text-error-redx"
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600"
                      >
                        <div className="w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full" />
                        <div className="w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full" />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handleNext}
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === parsedData.length - 1}
                  >
                    <Icon icon="mingcute:right-line" className="text-25" />
                  </button>
                )}
            </div>
            {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying ? (
              <TabPanels className={"py-24"}>
                {parsedData.map((section, index) => (
                  <TabPanel key={index}>
                    <div className="pt-5" key={index}>
                      <SelectedCard
                        title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                        data={[
                          {
                            title: "Archetype Name",
                            content: section.archetype_content.archetype_name,
                          },
                          {
                            title: "Demographic",
                            content: section.archetype_content.demographics,
                          },
                          {
                            title: "Occupation",
                            content: section.archetype_content.occupation,
                          },
                          {
                            title: "Lifestyle",
                            content: section.archetype_content.lifestyle,
                          },
                          {
                            title: "Behavior",
                            content: section.archetype_content.behavior,
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              section.archetype_content.needs_and_challenges,
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              section.archetype_content
                                .potential_buying_motives,
                          },
                          {
                            title: "Current Trends",
                            content: section.archetype_content.current_trends,
                          },
                          {
                            title: "Source of Information",
                            content:
                              section.archetype_content.source_of_information,
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              section.archetype_content.online_search_behavior,
                          },
                          {
                            title: "Purchase Frequency",
                            content:
                              section.archetype_content.purchase_frequency,
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              section.archetype_content
                                .preferred_sales_channels,
                          },
                        ]}
                      />
                      <div className="mt-20" />
                      <SliderContainer
                        isEditing={isEditing}
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        length={section.opportunities.length}
                        selectedIndexes={selectedOpportunityIndex}
                        onActiveIndex={(index) =>
                          isAdding ? setActiveIndex(0) : setActiveIndex(index)
                        }
                      >
                        {section.opportunities.map((item, index) => (
                          <Card
                            project={project}
                            key={index}
                            index={index}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isFetching={isRefetching}
                            item={
                              {
                                ...item.opportunities_content,
                                id: item.id,
                                is_selected: item.is_selected === 1,
                              } as OpportunityAmongCompetitionProps
                            }
                            totalItem={section.opportunities.length}
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[index] = el)}
                            onSuccessSelected={(status) => {
                              refetch();
                              setShowAlertGenerateModal(status);
                            }}
                          />
                        ))}
                        {isAdding && (
                          <Card
                            project={project}
                            index={section.opportunities.length + 1}
                            isAdding={isAdding}
                            setIsAdding={setIsAdding}
                            item={
                              {
                                id: -1,
                                brand_persona: "",
                                features_and_benefits: "",
                                creative_communication_angle: "",
                                digital_key_touch_points: "",
                                opportunity_among_competition_number:
                                  section.opportunities.length + 1,
                                is_selected: false,
                              } as OpportunityAmongCompetitionProps
                            }
                            totalItem={section.opportunities.length}
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[0] = el)}
                          />
                        )}
                      </SliderContainer>
                      <div className="flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx">
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className="text-15 font-semibold inline-flex text-blue-redx gap-x-4"
                              onClick={toggleIsAdding}
                            >
                              Add Idea manually
                              <Icon icon="lucide:plus" className="w-20 h-20" />
                            </button>
                          ) : (
                            <div></div>
                          ))}
                        <div className="flex justify-end items-center gap-x-15">
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <Fragment>
                                <button
                                  disabled={updateLoading}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    updateLoading
                                      ? "text-gray-redx"
                                      : "text-red-500"
                                  }`}
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }
                                >
                                  <span className="text-15 font-semibold">
                                    Cancel
                                  </span>
                                  <Icon icon="lucide:x" className="w-20 h-20" />
                                </button>
                                <button
                                  disabled={updateLoading}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    updateLoading
                                      ? "text-gray-redx"
                                      : "text-blue-redx"
                                  }`}
                                  onClick={handleSubmit}
                                >
                                  <span className="text-15 font-semibold">
                                    Save
                                  </span>
                                  <Icon
                                    icon="lucide:save"
                                    className="w-20 h-20"
                                  />
                                </button>
                              </Fragment>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className="flex w-full pb-60">
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className="block">
                <div className="w-full h-55 animate-pulse bg-soft-purple-redx rounded-8" />
                <div className="mt-20" />
                <SliderContainer
                  isEditing={isEditing}
                  isAdding={isAdding}
                  length={1}
                  goToIndex={goToIndex}
                  onActiveIndex={undefined}
                >
                  <Card
                    project={project}
                    totalItem={0}
                    item={
                      {
                        brand_persona: "",
                        features_and_benefits: "",
                        creative_communication_angle: "",
                        digital_key_touch_points: "",
                        is_selected: false,
                      } as OpportunityAmongCompetitionProps
                    }
                    isFetching={true}
                    index={-1}
                    isEditing={isAdding}
                    ref={(el) => (cardRefs.current[-1] = el)}
                  />
                </SliderContainer>
                <div className="flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx">
                  {isEditor &&
                    (!isAdding && !isEditing ? (
                      <button
                        className="text-15 font-semibold inline-flex text-blue-redx gap-x-4"
                        onClick={undefined}
                      >
                        Add Opportunity Manually
                        <Icon icon="lucide:plus" className="w-20 h-20" />
                      </button>
                    ) : (
                      <div></div>
                    ))}
                  <div className="flex justify-end items-center gap-x-15">
                    {isEditor &&
                      (isEditing || isAdding ? (
                        <Fragment>
                          <button
                            className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">
                              Cancel
                            </span>
                            <Icon icon="lucide:x" className="w-20 h-20" />
                          </button>
                          <button
                            disabled={false}
                            className={`inline-flex items-center gap-x-4 ${
                              false ? "text-gray-600" : "text-blue-redx"
                            } cursor-pointer`}
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">Save</span>
                            <Icon icon="lucide:save" className="w-20 h-20" />
                          </button>
                        </Fragment>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className="flex w-full pb-60">
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateNextProcess}
        onClose={() => setShowRegenerateModal(false)}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
