import { fetcher } from "@/Services/axios";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setMarketResearchDemand,
  setMarketResearchTrends,
} from "@/Context/actions/projectActions";
import { useGenerate } from "@/Context/hooks/useGenerate";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { ProjectProps } from "@/Types/projects";

import GenerateLoading from "@/Components/GenerateLoading";
import MarkdownEditor from "@/Components/MarkdownEditor";
import MarkdownEditorReadOnly from "@/Components/MarkdownEditorReadOnly";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SourcesList from "@/Components/Projects/SourcesList";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { historiesFilter } from "@/Helpers/filter";
import useLatestHistory from "@/Hooks/react-query/useHistoryLatest";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import { SectionList } from "@/Types/tabs";
import { progressPortion } from "@/Utils/dispatcher";
import toast from "react-hot-toast";

const MarketResearchIndex: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const maxGenerateLimit = project.max_generated_data;
  const { data: user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState("");
  const { roles } = useRolesBadge(project, user);
  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isUpdateState: isPendingMarketResearch,
  } = useHistoryStatus(project);
  const [isGenerateTrends, setIsGenerateTrends] = useState(false);
  const [isGenerateDemand, setIsGenerateDemand] = useState(false);
  const { handleGenerateMarketTrends, handleGenerateMarketDemand } =
    useGenerate(dispatch);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const sectionList: SectionList[] = [
    {
      title: "Trends",
      value: "trends",
      section: "market_research",
    },
    {
      title: "Demand",
      value: "demand",
      section: "market_research",
    },
  ];
  const totalRegenerate: { [key: string]: number } = {
    trends: state.marketResearch.trends.data.total_regenerate ?? 0,
    demand: state.marketResearch.demand.data.total_regenerate ?? 0,
  };

  const handleRegenerateButton =
    (section: string) => (data: { prompt: string }) => {
      if (section === "trends") {
        setIsGenerateTrends(true);
        handleGenerateMarketTrends()(project.slug, data.prompt);
      }

      if (section === "demand") {
        setIsGenerateDemand(true);
        handleGenerateMarketDemand()(project.slug, data.prompt);
      }
    };

  const toggleIsEditing = (content: string) => {
    setEditedData(content);
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setEditedData("");
    setIsEditing(!isEditing);
  };

  const handleSubmitEdit = (subsection: string) => async () => {
    const historyId =
      state.marketResearch[subsection as keyof typeof state.marketResearch].data
        .history_id;

    const { data } = await fetcher.put(
      `/${project.slug}/history/${historyId}`,
      { content: editedData }
    );

    if (subsection === "demand") dispatch(setMarketResearchDemand(data.data));
    if (subsection === "trends") dispatch(setMarketResearchTrends(data.data));
    setIsEditing(!isEditing);
  };

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const { data, isLoading, refetch } = useLatestHistory(project);

  useEffect(() => {
    const marketResearchTrends = historiesFilter({
      data,
      section: "market_research",
      subSection: "trends",
    });
    const marketResearchDemand = historiesFilter({
      data,
      section: "market_research",
      subSection: "demand",
    });

    if (marketResearchTrends?.history_id) {
      dispatch(setMarketResearchTrends(marketResearchTrends));
    } else {
      if (
        !isLoading &&
        !isPendingMarketResearch &&
        !isMarketResearchTrend.status
      ) {
        setIsGenerateTrends(true);
        !isMarketResearchTrend.isQuerying &&
          handleGenerateMarketTrends()(project.slug);
      }
    }

    if (marketResearchDemand?.history_id) {
      dispatch(setMarketResearchDemand(marketResearchDemand));
    } else {
      if (
        !isLoading &&
        !isPendingMarketResearch &&
        !isMarketResearchDemand.status &&
        !isMarketResearchDemand.isQuerying
      ) {
        setIsGenerateDemand(true);
        !isMarketResearchDemand.isQuerying &&
          handleGenerateMarketDemand()(project.slug);
      }

      if (!isMarketResearchDemand.isQuerying) {
        setIsGenerateDemand(false);
      }

      if (!isMarketResearchTrend.isQuerying) {
        setIsGenerateTrends(false);
      }
    }
  }, [
    data,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPendingMarketResearch,
  ]);

  const routeOptionValue: RouteOption = {
    label: "Next to Target Audience",
    isActive: true,
    isDisabled: !state.submission.is_audience_information_complete,
    onClick: () => navigate(`/${project.slug}/target-audience`),
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/product-research`),
      })
    );
    dispatch(saveInformation(""));
  }, []);

  useEffect(() => {
    let newIsDisabled =
      !isMarketResearchDemand.status && !isMarketResearchTrend.status;
    if (isEditing) {
      newIsDisabled = true;
    } else {
      newIsDisabled = false;
    }

    routeOptionValue.isDisabled = newIsDisabled;

    if (
      !isPendingMarketResearch &&
      (isTargetAudienceAudienceInsight.status ||
        isTargetAudienceMediaConsumptionPattern.status ||
        isTargetAudiencePerceptionAnalysis.status ||
        isTargetAudienceAudienceInsight.isQuerying ||
        isTargetAudienceMediaConsumptionPattern.isQuerying ||
        isTargetAudiencePerceptionAnalysis.isQuerying)
    ) {
      routeOptionValue.isInactive = false;
      routeOptionValue.onClick = () =>
        navigate(`/${project.slug}/target-audience`);
    } else {
      routeOptionValue.isInactive = true;
      routeOptionValue.onClick = () => {
        if (!isEditor) {
          setShowViewInfoModal(true);
          return;
        }

        dispatch(
          saveSubInformation(
            "{text-error-redx} Please input Target Audience in the Submission first."
          )
        );
      };
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      })
    );
  }, [
    isMarketResearchDemand,
    isMarketResearchTrend,
    isEditing,
    isPendingMarketResearch,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isEditor,
  ]);

  useEffect(() => {
    if (
      isMarketResearchDemand.status &&
      !isMarketResearchDemand.isQuerying &&
      state.marketResearch["demand" as keyof typeof state.marketResearch].data
        .content
    ) {
      setIsGenerateDemand(false);
    }

    if (isMarketResearchDemand.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      isMarketResearchTrend.status &&
      !isMarketResearchTrend.isQuerying &&
      state.marketResearch["trends" as keyof typeof state.marketResearch].data
        .content
    ) {
      setIsGenerateTrends(false);
    }

    if (isMarketResearchTrend.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      (!isMarketResearchTrend.isQuerying && isMarketResearchTrend.status) ||
      (!isMarketResearchDemand.isQuerying && isMarketResearchDemand.status)
    ) {
      refetch();
    }

    if (
      !isPendingMarketResearch &&
      (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying)
    ) {
      isMarketResearchDemand.isQuerying && setIsGenerateDemand(true);
      isMarketResearchTrend.isQuerying && setIsGenerateTrends(true);
    }
  }, [
    isPendingMarketResearch,
    isGenerateDemand,
    state.marketResearch,
    isMarketResearchDemand,
    isMarketResearchTrend,
  ]);

  return (
    <Fragment>
      <title title="Market Research: Industry" />
      <div className="w-full h-full">
        <div className="w-full sticky top-56 z-20 bg-white py-16">
          <h1 className="text-25 font-bold mb-8 leading-none">
            Market Research: Industry
          </h1>
          <span className="text-15 font-normal text-grey-redx">
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup className="pb-80">
          <TabList className={"flex w-full"}>
            {sectionList.map((section, index) => (
              <Tab
                disabled={isEditing}
                className="text-15 px-45 pb-10 border-b-1 text-black-redx w-1/2 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0"
                key={index}
              >
                {section.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels className={"py-24"}>
            {sectionList.map((section, index) => (
              <TabPanel key={index}>
                {(section.value === "demand" && isGenerateDemand) ||
                (section.value === "trends" && isGenerateTrends) ||
                !state.marketResearch[
                  section.value as keyof typeof state.marketResearch
                ].data.content?.length ? (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (
                          state.marketResearch[
                            section.value as keyof typeof state.marketResearch
                          ].progress / 2
                        ).toFixed(0)
                      ),
                      isQuerying:
                        section.value === "demand"
                          ? isMarketResearchDemand.isQuerying
                          : section.value === "trends"
                            ? isMarketResearchTrend.isQuerying
                            : false,
                      isContentAvailable:
                        state.marketResearch[
                          section.value as keyof typeof state.marketResearch
                        ].data.content !== "",
                    })}
                    project={project}
                    section={section}
                    showEmailNotification={
                      section.value === "demand"
                        ? isMarketResearchDemand.isSendingEmail
                        : section.value === "trends"
                          ? isMarketResearchTrend.isSendingEmail
                          : false
                    }
                  />
                ) : (
                  <Fragment>
                    <div className="flex w-full justify-between items-center mb-8">
                      <h1 className="text-15 font-semibold">{section.title}</h1>
                      {isEditor && !isEditing && (
                        <RegenerateButton
                          limit={totalRegenerate[section.value]}
                          maxLimit={maxGenerateLimit}
                          onSubmit={handleRegenerateButton(section.value)}
                        />
                      )}
                    </div>
                    {isEditing ? (
                      <div className="mb-12">
                        <MarkdownEditor
                          content={
                            state.marketResearch[
                              section.value as keyof typeof state.marketResearch
                            ].data.content ?? ""
                          }
                          onChange={(html) => {
                            setEditedData(html);
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        {!isLoading &&
                          state.marketResearch[
                            section.value as keyof typeof state.marketResearch
                          ].data !== null && (
                            <MarkdownEditorReadOnly
                              content={
                                state.marketResearch[
                                  section.value as keyof typeof state.marketResearch
                                ].data.content
                              }
                            />
                          )}
                        {isLoading && (
                          <div className="w-full h-155 px-15 py-12 my-20 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx" />
                        )}
                      </>
                    )}
                    <div className="flex w-full justify-end items-center mb-24 gap-x-15">
                      {isEditor &&
                        (isEditing ? (
                          <Fragment>
                            <button
                              className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
                              onClick={handleCancelEdit}
                            >
                              <span className="text-15 font-semibold">
                                Cancel
                              </span>
                              <Icon icon="lucide:x" className="w-20 h-20" />
                            </button>
                            <button
                              className="inline-flex items-center gap-x-4 text-blue-redx cursor-pointer"
                              onClick={handleSubmitEdit(section.value)}
                            >
                              <span className="text-15 font-semibold">
                                Save
                              </span>
                              <Icon icon="lucide:save" className="w-20 h-20" />
                            </button>
                          </Fragment>
                        ) : (
                          <EditButton
                            toggleEditing={() =>
                              toggleIsEditing(
                                state.marketResearch[
                                  section.value as keyof typeof state.marketResearch
                                ].data.content
                              )
                            }
                          />
                        ))}
                    </div>
                    <div className="flex w-full pb-60 mr-1">
                      {!isLoading &&
                        state.marketResearch[
                          section.value as keyof typeof state.marketResearch
                        ].data.sources && (
                          <SourcesList
                            sources={
                              state.marketResearch[
                                section.value as keyof typeof state.marketResearch
                              ].data.sources
                            }
                          />
                        )}
                      {isLoading && (
                        <div className="inline-flex gap-10">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <div
                              key={index}
                              className="min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default MarketResearchIndex;
