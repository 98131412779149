import { Fragment, useEffect, forwardRef, useImperativeHandle } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import _ from "lodash";

import { Recommendation } from "@/Types/key_touch_point";
import CardInput from "@/Components/Projects/SliderCard/CardInput";
import toast from "react-hot-toast";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import { useUpdateSelectedKeyTouchPoint } from "@/Hooks/react-query/key-touch-point";
import { ProjectProps } from "@/Types/projects";
import { CardHandles } from "../Index";

const schema = z.object({
  digital_recommendation: z
    .string()
    .min(4, "Digital recommendation is required"),
  idea_example: z.string().min(4, "Idea example is required"),
  success_measurement: z.string().min(4, "Success measurement is required"),
  media_types: z.string().min(4, "Media types is required"),
  reason: z.string().min(4, "Reason is required"),
  keyword: z.string(),
  trending_topics: z.string(),
});

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    index: number;
    project: ProjectProps;
    item?: Recommendation;
    isSelected?: boolean;
    isEditing?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    selectedFramework: string;
    totalData?: number;
    onSuccessSelected?: () => void;
    onHandleSubmit?: (data: Recommendation, id: number, index: number) => void;
  }
>(
  (
    {
      id,
      index,
      project,
      item,
      selectedFramework,
      onHandleSubmit,
      isEditing = false,
      isEditor,
      isFetching = false,
      isSelected = false,
      totalData = 0,
      onSuccessSelected,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedKeyTouchPoint();

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<Recommendation>({
      resolver: zodResolver(schema),
      defaultValues: {
        digital_recommendation: item?.digital_recommendation ?? "",
        idea_example: item?.idea_example ?? "",
        success_measurement: item?.success_measurement ?? "",
        media_types: item?.media_types ?? "",
        keyword: item?.keyword ?? "",
        trending_topics: item?.trending_topics ?? "",
        reason: item?.reason ?? "",
      },
    });

    useEffect(() => {
      setValue("digital_recommendation", item?.digital_recommendation ?? "");
      setValue("idea_example", item?.idea_example ?? "");
      setValue("success_measurement", item?.success_measurement ?? "");
      setValue("media_types", item?.media_types ?? "");
      setValue("reason", item?.reason ?? "");
      setValue("keyword", item?.keyword ?? "");
      setValue("trending_topics", item?.trending_topics ?? "");
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: Recommendation) => {
      onHandleSubmit && onHandleSubmit(data, id, index);
    };

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSuccessSelected && onSuccessSelected();
          },
        },
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          id === -1 ? (
            <div className='inline-flex items-center justify-center w-full gap-8 font-semibold'>
              {selectedFramework}
              <div className='w-1 h-20 border-1 border-stroke-redx' />
              <p>New Touchpoint</p>
            </div>
          ) : (
            <div className='inline-flex items-center justify-center w-full gap-8 font-semibold'>
              {selectedFramework}
              <div className='w-1 h-20 border-1 border-stroke-redx' />
              <p>
                Touchpoint {index + 1} of {totalData}
              </p>
            </div>
          )
        ) : (
          <div className='inline-flex items-center justify-center w-full gap-8'>
            <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
            <div className='w-1 h-20 border-1 border-stroke-redx' />
            <div className='w-2/5 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
          </div>
        )}

        <div
          className={`flex flex-col w-full gap-y-8 text-black-redx py-20 mt-10 px-18 ${
            isEditing && isSelected ? "!bg-transparent" : ""
          }
        ${
          isSelected && !isFetching
            ? "border-t-2 border-blue-redx bg-softer-purple-redx"
            : "border-t-1 border-stroke-redx"
        }`}>
          <div className={`grid grid-cols-2 gap-x-24 gap-y-15 pb-8`}>
            <Fragment>
              <Controller
                control={control}
                name='digital_recommendation'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.digital_recommendation?.message}
                    placeholder='Input the digital recommendation here'
                    title={"Digital Recommendation"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='idea_example'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.idea_example?.message}
                    placeholder='Input the idea example here'
                    title={"Idea Example"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='success_measurement'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.success_measurement?.message}
                    placeholder='Input the success measurement here'
                    title={"Success Measurement"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='media_types'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.media_types?.message}
                    placeholder='Input the media types here'
                    title={"Media Types"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
              {selectedFramework == "Searchability" && (
                <Controller
                  control={control}
                  name='keyword'
                  render={({ field }) => (
                    <CardInput
                      isEditing={isEditing}
                      isFetching={isFetching}
                      defaultValue={field.value}
                      error={errors.keyword?.message}
                      placeholder='Input the keyword here'
                      title={"Keyword"}
                      showTitle={true}
                      {...field}
                    />
                  )}
                />
              )}
              {selectedFramework == "Discoverability" && (
                <Controller
                  control={control}
                  name='trending_topics'
                  render={({ field }) => (
                    <CardInput
                      isEditing={isEditing}
                      isFetching={isFetching}
                      defaultValue={field.value}
                      error={errors.trending_topics?.message}
                      placeholder='Input the trending topics here'
                      title={"Trending Topics"}
                      showTitle={true}
                      {...field}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name='reason'
                render={({ field }) => (
                  <CardInput
                    isEditing={isEditing}
                    isFetching={isFetching}
                    defaultValue={field.value}
                    error={errors.reason?.message}
                    placeholder='Input the reason here'
                    title={"Reason"}
                    showTitle={true}
                    {...field}
                  />
                )}
              />
            </Fragment>
          </div>
        </div>

        <div className='flex w-full justify-end px-1 py-15'>
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              index={index}
              isLoading={isUpdatingSelected}
              isEditing={isEditing}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
