import React from "react";
import { Icon } from "@iconify/react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import _ from "lodash";
import { SelectedCardProps } from "@/Types/selected_data";
import { useNavigate } from "react-router-dom";

const CardContent: React.FC<{
  title: string;
  content: string;
}> = ({ title, content }) => {
  return (
    <div className='flex flex-col gap-5'>
      <h3 className='text-black-redx font-semibold text-12 leading-14'>
        {title}
      </h3>
      <p className='text-14 leading-20 font-normal'>{content}</p>
    </div>
  );
};

const CardHeader: React.FC<{
  label: string;
  subLabel: string;
  open: boolean;
  onClick: () => void;
}> = ({ label, subLabel, open, onClick }) => {
  return (
    <div className='flex-grow flex items-center justify-between'>
      <div className='flex flex-col gap-8 items-start'>
        <h3 className='text-14 font-bold leading-16 text-black-redx'>
          {label}
        </h3>
        {!open && (
          <h3 className='text-14 font-bold leading-16 text-black-redx'>
            {subLabel}
          </h3>
        )}
      </div>
      <Icon
        onClick={onClick}
        icon={open ? "mdi:chevron-up" : "mdi:chevron-down"}
        className='w-24 h-24 text-black-redx cursor-pointer'
      />
    </div>
  );
};

const SelectedKeyTouchPointCard: React.FC<{
  archetypeId: number;
  projectSlug: string;
  title: string;
  subtitle: string;
  data: SelectedCardProps[];
}> = ({ title, subtitle, data }) => {
  const navigate = useNavigate();

  return (
    <Popover className='relative bg-soft-purple-redx border-b-1 border-t-1 border-b-blue-purple-redx border-t-blue-purple-redx data-[open]:opacity-0'>
      <PopoverButton className='w-full p-16 z-30'>
        <div className='flex flex-col gap-12'>
          <CardHeader
            label={title}
            subLabel={subtitle}
            open={false}
            onClick={() => {}}
          />
        </div>
      </PopoverButton>
      <Transition
        enter='transition ease-out duration-0'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-0'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'>
        <PopoverPanel
          anchor={{ to: "bottom end", gap: -53 }}
          className='shadow-[0px_8px_12px_0px_rgba(0,0,0,0.12)] bg-soft-purple-redx border-b-1 border-t-1 border-b-blue-purple-redx border-t-blue-purple-redx p-16 w-[var(--button-width)] z-30'>
          {({ close }) => (
            <div className='flex flex-col gap-12 relative'>
              <CardHeader
                label={title}
                subLabel={subtitle}
                open={true}
                onClick={() => close()}
              />

              <div className='grid grid-cols-2 gap-16 text-left min-h-full max-h-250 overflow-scroll'>
                {data.map((item, index) => (
                  <CardContent
                    key={index}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SelectedKeyTouchPointCard;
